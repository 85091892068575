import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { colorSetProperty } from "../../helpers/helpers";

class Header extends Component {
    state = {
        park_data: {}
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props.parks) {
            let park_data = this.props.parks.filter(park => park.id == this.props.park_id)
            this.setState({ 
                park_data: park_data[0],
            })
        }
    }
    
    render() {
        let { park_data } = this.state;
        // assuming belgrave's logo is default park logo
        let default_logo = (this.props.parks !== null && this.props.parks.length > 0) ?
                            (<a><img src={`data:image/png;base64,${this.props.parks[0].logo_web}`} height="30" /></a>) :
                            (<a> TARONGA ZOO </a>);

        const currentUrl = document.location.hash.split("/");
        let checkSelectPark = currentUrl.includes("order") && currentUrl.includes("0") ? true : false;

        return (
            <div className="nav" style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
                <div>
                    {park_data && park_data.logo_web ?
                        <a><img src={`data:image/png;base64,${park_data.logo_web}`} height="30" /></a> :
                        default_logo
                    }
                </div>
                <div>
                    {!checkSelectPark ?
                        park_data && park_data.website ?
                        <a href={park_data.website}>Back to website</a> :
                        <a href='/'> Back to website </a> : ""
                    }
                </div>
            </div>
        );
    }
}
export default Header
