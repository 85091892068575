import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import {
  FaUserAlt,
  FaInfoCircle
} from "react-icons/fa";
import {
  api_get_booking,
  api_create_booking_step4_check,
  api_get_windcave_session,
  api_park_cache
} from "../../api/api";
import { numberSpinner, colorSetProperty, setGoogleFont, setTitle } from "../../helpers/helpers";
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate
} from "../OrderProcess/step5/utils";
import StepperPayLink from "../Layout/StepperPayLink";
import WindcaveCard from "../OrderProcess/step6/WindcaveCard";
import axiosRetry from 'axios-retry';

let numberError = "",
    expireError = "",
    cvcError = "",
    timeSpinner = "";

export default class PaymentLink extends Component {
  state = {
    isLoading: true,
    nameClass: "",
    numberClass: "",
    expireClass: "",
    expireError: "",
    focused: "",
    success_page: true,
    checkTerm: false,
    redirectConfirmPage: false,
    redirectToIndex: false,
    // booking data
    pay_amount: 0,
    booking_id: null,
    booking_data: {},
    booking_name: '',
    booking_date: '',
    booking_park: '',
    booking_total_amount: 0.00,
    booking_amount_paid: 0.00,
    booking_amount_outstanding: 0.00,
    // park
    parks: null,
    updatedFont: false,
    updatedTitle: false,
    windcaveLink: undefined,
    previousWindcaveLink: undefined,
    park_id: null,
    windcaveSession: null,
    previousWindcaveSession: false,
    error: {
      errorCode: false,
      errorMsg: false,
      errorDetail: false,
      counter: 0,
    },
    isIOS: false,
    isDeposit: '0',
    account: {
      saleType: false,
      commision: 0
    }
  }

  componentWillMount(){
    const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }

  computeBookingDate (result) {
    console.log(result)
    const time = result.booking_detail[0].start_session.time.toString().split('.');
    const hour = time[0].toString();
    var min = "";
    if (time.length == 1) {
      min = "00";
    }
    else {
      min = parseInt(time[1] * 6);
    }
    console.log(time.length)
    const booking_date = result.booking_detail[0].start_session.date + " " + hour + ":" + min;
    return booking_date;
  }

  componentDidMount() {
    // initiate data
    let params = new URLSearchParams(this.props.location.search);
    let booking_id = params.get("booking_id");
    let amount = params.get('amount');
    let deposit = params.get('deposit');
    this.setState({isDeposit: deposit})

    if (booking_id) {
      // Hit to backend
      this.postToApi(
        api_get_booking, {
        booking_id: booking_id
      }).then(({ data }) => {
        let { result } = data;
        amount = amount ? amount : result.data[0].amount_due
        if(result.data[0].amount_due.toFixed(2) <= 0 && result.data[0].state == 'confirmed'){
          alert("Booking "+ result.data[0].display_name + " is fully paid");
          this.setState({ redirectToIndex: true });
        }
        else if (result.data[0].state != 'pending_payment') {
          alert("Booking " + result.data[0].display_name + " is a " + result.data[0].state + " booking.");
          this.setState({ redirectToIndex: true });
        }
        else if (amount > result.data[0].amount_due){
          alert(result.data[0].display_name + ": \nAmount to pay is more than amount due");
          this.setState({ redirectToIndex: true });
        }
        else if (amount >= 1) {
          // set to state
          this.setState({
            booking_data: result,
            booking_id: parseInt(booking_id),
            booking_name: result.data[0].display_name,
            booking_date: this.computeBookingDate(result),
            booking_park: result.data[0].location_id[1],
            booking_total_amount: (result.data[0].amount).toFixed(2),
            booking_amount_paid: (result.data[0].amount_paid).toFixed(2),
            booking_amount_outstanding: (result.data[0].amount_due).toFixed(2),
            pay_amount: parseFloat(amount).toFixed(2),
            park_id: result.data[0].location_id[0],
            account: {
              saleType: result.data[0].account_sale_type,
              commision: result.data[0].commision
            } 
          }, () => {
            this.getParkData(result.data[0].location_id[0]);
            this.get_windcave_session();
          });
          //this.getParkData(result.data[0].location_id[0]);
          //this.get_windcave_session();
        
        }else {
          alert(`booking id '${booking_id}' already paid`);
          this.setState({ redirectToIndex: true });
        }
      })
    }else {
      alert("there's no booking id");
      this.setState({ redirectToIndex: true });
    }
  }

  fetchNewSession = () => {
    this.postToApi(api_get_windcave_session, {
      total_amount: parseFloat(this.state.pay_amount),
      booking_id: this.state.booking_id,
      previous_windcave_session: this.state.previousWindcaveSession,
      windcave_session: this.state.windcaveSession,
      exp_from_record: false,
      full_page: true,
      payment_method: ['applepay', 'card'],
    })
    .then(({ data }) => {
      console.log(data)
      if ("result" in data) {
        
        if (data.result === false) {
          window.location.reload()
        }

        window.localStorage.setItem('parks', JSON.stringify(this.state.parks))
        window.localStorage.setItem('pay_amount', parseFloat(this.state.pay_amount))
        const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
        window.location.href = windcaveRef
      }
    })
  }
  
  get_windcave_session = (declined) => {
    if (this.state.error.counter == 2) {
      window.location.reload()
    }
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let hasSafari = userAgent.match(/Safari/i);
    let hasOtherBrowser = /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox|GSA/i.test(userAgent)
    let isAppleVendor = /Apple/i.test(navigator.vendor);
    let iosDevice = /iPad|iPhone|iPod/.test(userAgent)
    let isMac = /Macintosh/.test(userAgent)
    let pay_method = ['card']

    // Apple Pay only works on ios device and safari
    if ( (isMac || iosDevice) && hasSafari && isAppleVendor && !hasOtherBrowser) {
      this.setState({isIOS: true})
      pay_method.push('applepay');
    }
    // Google pay works on most of the android, browser and mac
    if (!iosDevice || ( isMac && hasOtherBrowser )) {
      pay_method.push('googlepay');
    }
    
    this.setState({
      previousWindcaveSession: this.state.windcaveSession
    });

    let state = { ...this.state };
    this.postToApi(
      api_get_windcave_session, {
        total_amount: parseFloat(this.state.pay_amount),
        booking_id: this.state.booking_id,
        previous_windcave_session: this.state.previousWindcaveSession,
        windcave_session: this.state.windcaveSession,
        exp_from_record: false,
        payment_method: pay_method,
        deposit: this.state.isDeposit
    })    
    .then(({ data }) => {
      if ("result" in data) {

        if (data.result === false || data.result == 'expired') {
          alert("Session has expired, you have not been charged");
          window.location.reload()
        }
        else {
          const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
          const currentWindcaveLink = this.state.windcaveLink;
          if ("error" in data['result']) {
            this.setState({
              error: {
                errorCode: data['result']['error']['errorCode'],
                errorMsg: data['result']['error']['errorMsg'],
                errorDetail: data['result']['error']['errorDetail'],
                counter: this.state.error.counter + 1
              }
            });
            console.log(this.state.error.counter)
          }
          this.setState({
            windcaveLink: windcaveRef,
            previousWindcaveLink: currentWindcaveLink,
            windcaveSession: data["result"]["id"]
          });
        }
      }
    })
  }

  componentDidUpdate() {
    // Call Customize
    if (this.state.parks) {
      this.customizeColor(this.state.parks);
      if (this.state.updatedTitle) {
        setTitle(this.state.parks.name);
        this.setState({ updatedTitle: true })
      }
      // - custom font -
      let snipFont = this.state.parks.t_google_font_snippets + ' <style> body {' + this.state.parks.t_font_to_use + '} </style>';
      if (snipFont && this.state.updatedFont == false) {
        setGoogleFont(snipFont);
        this.setState({ updatedFont: true })
      }
    }
  }

  // Tools
  // - Post Api Data Handler -
  postToApi = (url, param) => {
    let params = {
      jsonrpc: "2.0",
      method: "call",
      id: null,
      params: param
    };
    return axios.post(url, params, {
      headers: { "Content-Type": "application/json;charset=utf-8" }
    });
  }

  // - Get Park Data -
  getParkData = (park_id) => {
      axios.get(api_park_cache).then(({ data }) => {
        let { result } = data;
        let res = result.filter(park => park.id == park_id);
        this.setState({ parks: res}); 
      }).catch(() => {
        alert(`park id '${park_id}' not found`);
        this.setState({ redirectToIndex: true });
    })
  }
  
  customizeColor(park) {
    const parkData = park[0];
    // Customize color
    const colors = {
        'color1': parkData.t_color1,
        'color2': parkData.t_color2,
        'color3': parkData.t_color3,
        'color4': parkData.t_color4,
    }
    colorSetProperty(colors);
  }

  // - save payment -
  savePayment = payment => {

    this.state.error.errorCode = false;
    this.state.error.errorMsg = false;
    this.state.error.errorDetail = false;
    
    this.toggleLoading(true);

    const client = axios.create({ baseURL: api_create_booking_step4_check, headers: {'Content-Type': 'application/json'} });
    axiosRetry(client, { retries: 30, retryDelay: (retryCount) => {
      return retryCount * 750;
    } });
    let parameters = {
      booking_id: this.state.booking_id,
      windcave_session: this.state.windcaveSession,
      pay_amount: parseFloat(this.state.pay_amount),
    };

    client.get("/check", { params: parameters }).then( ({data}) => {
      console.log("RETURN HIT", data)
      let error = false
      let payment_info = false
      let amount_info = false
      if (data.error) {
        this.setState({success_page: false})
        error = data.error.data.message
      } else {
        payment_info = '{"id":"' + data.payments[0].id + '", "name":"'+ data.payments[0].name + '", "transaction_number":"' + data.payments[0].transaction_number +'"}'
        amount_info = '{"total_amount":"' + data.data[0].amount + '", "amount_paid":"' + data.data[0].amount_paid + '", "amount_due":"' + data.data[0].amount_due +'"}'
      }
      // set to localstorage the response and booking data
      let val = '{"id": "'+ this.state.booking_id +'", "name": "'+ this.state.booking_name +'","pay_amount":"' + this.state.pay_amount+'","error":"'+error+'","success_page":"' + this.state.success_page +'"}'
      window.localStorage.setItem('booking_data', val)
      window.localStorage.setItem('account_sale_type', this.state.account.saleType)
      window.localStorage.setItem('payment_info', payment_info)
      window.localStorage.setItem('amount_info', amount_info)
      window.localStorage.setItem('parks', JSON.stringify(this.state.parks))
      console.log("val:", val);

      this.setState({redirectConfirmPage: true})
    });
  };

  // - spinner -
  spinnerTimeout() {
    timeSpinner = setTimeout(
      function() {
        this.setState({isLoading: false})
      }
      .bind(this),
      numberSpinner()
    );
  }
  stopSpinnerTimout() {
    clearTimeout(timeSpinner)
  }

  // - loading -
  toggleLoading(value) {
    this.setState({ isLoading: value });
    if (value) {
      this.spinnerTimeout()
    } else {
      this.stopSpinnerTimout()
    }
  }

  // - redirect to index page -
  redirectToIndex = () => {
    if (this.state.redirectToIndex) {
      return <Redirect to="/" />;
    } else if (this.state.redirectConfirmPage) {
      return (
        <Redirect
          to={{
              pathname:
              "/payment_status/" + this.state.booking_id,
              state: {success_page: this.state.success_page}
          }}
        />
      );
    }
  }

  // Component Handler
  // - input change handler -
  handleInputChange = ({ target }) => {
    // Retrieve Data
    let { name, value } = target;

    if (name === 'name') {
      if (value.length > 0) {
        this.setState({ nameClass: 'not-empty' });
      }else {
        this.setState({ nameClass: '' });
      }
    } else if (name === "number") {
      value = formatCreditCardNumber(value);
      if (value.length > 0) {
        this.setState({ numberClass: "not-empty" });
      }else {
        this.setState({ numberClass: "" });
      }
    } else if (name === "expiry") {
      value = formatExpirationDate(value);
      if (value.length > 0) {
        this.setState({ expiryClass: "not-empty" });
      } else {
        this.setState({ expiryClass: "" });
      }
    }else if (name === "cvv") {
      value = formatCVC(value);
      if (value.length > 0) {
        this.setState({ cvcClass: "not-empty" });
      } else {
        this.setState({ cvcClass: "" });
      }
    }

    this.setState({ [name]: value });
  }

  // - input focus handler -
  handleInputFocus = ({ target }) => {
    this.setState({ focused: target.name });
  }

  handleCheckbox = () => {
    this.setState({ checkTerm: !this.state.checkTerm });
  }

  // Sub Component Rendering
  // - credit card info -
  creaditInfo() {
    return (
      <React.Fragment>
        <div style={{paddingLeft: '25px'}}>
          <WindcaveCard
            type='booking_payment'
            cardClass="card-windcave"
            WindcaveCardCollapse={true}
            data={this.state.booking_id}
            actionCollapse={this.ActionCollapseState}
            parks={this.state.park_id}
            getNewSession={this.fetchNewSession}
            action={this.savePayment}
            isIOS={this.state.isIOS}
            totalAmount={this.state.pay_amount}
            actionWindcave={this.get_windcave_session}
            enableSubmit={this.state.profileEnableSubmit}
            windcaveLink={this.state.windcaveLink}
            previousWindcaveLink={this.state.previousWindcaveLink}
            toggleLoading={this.toggleLoading.bind(this)}
            error={this.state.error}
          />
        </div>
      </React.Fragment>
    )
  }

  // - booking details -
  renderBookingDetails() {
    // Initiate variable and data
    let items = [];
    let { booking_detail } = this.state.booking_data

    if (booking_detail !== undefined) {
      // render product name
      booking_detail.forEach((book, index) => {
        if (index > 0) {
          // make it indent
          items.push( <div key={`${index}-empty`} className="col-md-6 price-qty text-right"></div> )
        }
        items.push(
          <div key={index} className="col-md-6 price-qty text-right">
            <span className="label-detail text-left">
              {book.name}
            </span>
          </div>
        )
      });
    }
    // return result
    return items;
  }

  // - booking participants -
  renderBookingParticipant() {
    // initiate variable & data 
    let items = [];
    let { participants } = this.state.booking_data;
    
    if (participants !== undefined) {
      // render participant
      participants.forEach((participant, index) => {
        let is_prular = participant.quantity > 1 ? 's' : '';
        if (index > 0) {
          // make it indent
          items.push( <div key={`${index}-empty`} className="col-md-6 price-qty text-right"></div> )
        }
        items.push(
          <div key={index} className="col-md-6 price-qty text-right">
            <span className="label-detail text-left">
              {`${participant.quantity}x ${participant.segment_name}${is_prular}`}
            </span>
          </div>
        )
      });
    }
    return items;
  }

  render() {
    let step = [
      true,
      false
    ];
    let {
      booking_name,
      booking_date,
      booking_park,
      booking_total_amount,
      booking_amount_paid,
      booking_amount_outstanding,
      pay_amount,
      parks
    } = this.state;

    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let hasSafari = userAgent.match(/Safari/i);
    let hasOtherBrowser = /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox|GSA/i.test(userAgent)
    let isAppleVendor = /Apple/i.test(navigator.vendor);
    let iosDevice = /iPad|iPhone|iPod/.test(userAgent)
    
    return (
      <div className={this.state.isLoading === true ? 'loading': ''}>
        {this.redirectToIndex() /* redirecting to index */}
        <StepperPayLink step={step} parks={parks} />
        <div className='container' style={{ marginTop: 20 }}>
          <div className='row mbl-reverse'>
            <div className='col-md-6 col-sm-12 booking-details'>
              <div className="col col-xs-12 detail-container">
                <div className="row">
                  <div className="col-md-12 title">
                    BOOKING DETAILS
                  </div>
                </div>
                {/* Booking reference */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                    <b>Booking Reference</b>
                  </div>
                  <div className="col-md-6 price-qty text-right">
                    <span className="label-detail text-left">
                      <b>{booking_name}</b>
                    </span>
                  </div>
                </div>
                {/* Booking date */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                    Booking Date
                  </div>
                  <div className="col-md-6 price-qty text-right">
                    <span className="label-detail text-left">
                      {booking_date}
                    </span>
                  </div>
                </div>
                {/* Park */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                    Park
                  </div>
                  <div className="col-md-6 price-qty text-right">
                    <span className="label-detail text-left">
                      {booking_park}
                    </span>
                  </div>
                </div>
                {/* Booking details */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                    Booking Details
                  </div>
                  {this.renderBookingDetails()}
                </div>
                {/* Booking details */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                  </div>
                  {this.renderBookingParticipant()}
                </div>
                <br />
                {/* Total booking value */}
                {
                  !['agent_deposit', 'agent_prepaid'].includes(this.state.account.saleType) ?
                  <div className="row">
                    <div className="col-md-6 label-detail">
                      Total Booking Value
                    </div>
                    <div className="col-md-6 label-number">
                      {`$ ${booking_total_amount}`}
                    </div>
                  </div>
                  :
                  <>
                  </>
                }
                {/* Amount paid */}
                {
                  !['agent_deposit', 'agent_prepaid'].includes(this.state.account.saleType) ?
                  <div className="row">
                    <div className="col-md-6 label-detail">
                      Amount Paid
                    </div>
                    <div className="col-md-6 label-number">
                      {`$ ${booking_amount_paid}`}
                    </div>
                  </div>
                  :
                  <>
                  </>
                }
                <hr />
                {/* Amount outstanding */}
                <div className="row">
                  <div className="col-md-8 stotal">
                    {this.state.isDeposit == 1 ? 'Amount Due (20% Deposit)' : 'Amount Outstanding'}
                  </div>
                  <div className="col-md-4 label-number">
                    $ {this.state.pay_amount}
                  </div>
                </div>
              </div>
              <hr />

              <div className="col col-s-12 detail-container">
                  {this.creaditInfo()}

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
