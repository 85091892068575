import React, { Component } from "react";
import Stepper from "../Layout/Stepper";
import { FaUserAlt, FaFacebook, FaHome } from "react-icons/fa";
import Moment from "moment";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { api_get_booking_request, api_park_cache } from "../../api/api";
import axios from "axios";
import { unsetFooter, colorSetProperty, setGoogleFont, setHeadTag, setTitle } from "../../helpers/helpers";

class ConfirmRequest extends Component {
  state = {
    data: null,
    flag: null,
    openModal: false,
    bookingRequest: null,
    parks: null,
    park_id: null,
    park_data: null,
    updatedTitle: false,
    updatedFont: false,
    updatedHeadTag: false
  };

  componentDidMount() {
    console.log(this.props.data);
    if (!this.state.data) {
      let bookingRequestData = null;
      if (this.props.data) {
        bookingRequestData = this.props.data
        window.localStorage.setItem('request_data', JSON.stringify(bookingRequestData))
      }else {
        bookingRequestData = JSON.parse(window.localStorage.getItem('request_data'))
      }
      this.setState({ data: bookingRequestData })
    }
    this.getBookData()
  }

  componentDidUpdate() {
    let { park_data, updatedFont, updatedHeadTag } = this.state;
    if (park_data) {
      // Call set property
      this.customizeColor(park_data[0])
      if (this.state.updatedTitle == false) {
        setTitle(park_data[0].name);
        this.setState({ updatedTitle: true });
      }
      // - custom font -
      let snipFont = park_data[0].t_google_font_snippets + ' <style> body {' + park_data[0].t_font_to_use + '} </style>';
      if (snipFont && updatedFont == false) {
        setGoogleFont(snipFont);
        this.setState({ updatedFont: true })
      }
      // - head tag - 
      let snipheadtag = park_data[0].t_embed_head_tag;
      if (snipheadtag && updatedHeadTag == false) {
        setHeadTag(snipheadtag);
        this.setState({updatedHeadTag: true})
      }
    }else {
      this.getParkData();
      // - unset footer -
      unsetFooter();
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return 0;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getBookData = () => {
    const bookData = this.props.data ? this.props.data : JSON.parse(window.localStorage.getItem('request_data'));
    if (bookData) {
      const resultData = bookData.result.data[0]
      const params = {
        jsonrpc: "2.0",
        method: "call",
        params: {
          booking_request_id: resultData.id,
        }
      };
      axios.post(api_get_booking_request, params, {
        headers: {"Content-Type": "application/json;charset=UTF-8"}
      }).then(({ data }) => {
        this.setState({
          bookingRequest: data.result[0]
        })
      }).catch(({ error }) => {});
    }
  }
  
  getParkData = () => {
    const bookData = this.state.bookingRequest;
    if (bookData) {
      axios.get(api_park_cache).then(({ data }) => {
        let park_id = bookData.park_id[0];
        let park_data = data.result.filter(park => park.id == park_id)
        this.setState({
          park_id: park_id,
          parks: data.result,
          park_data: park_data
        })
      }).catch(({ error }) => {});
    }
  }

  handleGetParticipant = () => {
    let participants = this.state.data.result.participants;
    let res = '';
    for (let i = 0; i < participants.length; i++) {
      res += `${participants[i].segment_name} ${participants[i].quantity}, `;
    }
    return res.slice(0, -2);
  }

  openModalContact = () => {
    this.setState({
      openModal: !this.state.openModal
    })
  }

  modalContact = () => {
    let modal = ""
    if (this.state.park_data) {
      modal = (
        <Modal
          trigger="legacy" 
          className="contact-details"
          target="contactButton"
          isOpen={this.state.openModal}
          toggle={this.openModalContact}
        >
          <ModalHeader className="modal-contact-title">Contact Details</ModalHeader>
          <ModalBody className="style-modal">
            <div className="row margin-px">
              <div className="col col-md-12">
                <h6 className="modal-contact-body">Phone Number</h6>
                <p className="modal-contact-data">{this.state.park_data[0].phone}</p>
              </div>
              <div className="col col-md-12">
                <h6 className="modal-contact-body">Email</h6>
                <p className="modal-contact-data">{this.state.park_data[0].email}</p>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )
    }
    return (
      <React.Fragment>
        {modal}
      </React.Fragment>
    )
  }
  
  customizeColor(park) {
    // Customize color
    const colors = {
        'color1': park.t_color1,
        'color2': park.t_color2,
        'color3': park.t_color3,
    }
    colorSetProperty(colors);
  }
  
  render() {
    let step = [true, true, true, true, true];
    let valDate = "", participants = "";
    let emailCustomer = this.state.data ? this.state.data.result.customer.email : 'email';
    let nameCustomer = this.state.data ? this.state.data.result.data[0].customer_id[1] : '';
    let namePark = this.state.park_data ? this.state.park_data[0].name : '';
    let preference = this.state.data ? this.state.data.result.data[0].preference : '';
    const modalContact = this.modalContact();
    
    if (this.state.data) {
      valDate = Moment(
                this.state.data.result.data[0].desired_date
              ).format("dddd D MMMM ");
      participants = this.handleGetParticipant();
    }
    
    // Check park and render link or popup contact
    let contactButton = <a href="https://taronga.org.au/contact">contact the park</a>;
    if (this.state.park_data) {
      let parkName = this.state.park_data[0].name.toLowerCase();
      if (parkName.match(/next.*/)) {
        contactButton = (
          <span
            id="contactButton"
            name="contactButton"
            className="btn-confirm-contact"
            onClick={this.openModalContact}
            style={{lineHeight:"inherit",cursor:"pointer"}}
          >
          <text>contact the park</text>
          </span>
        )
      }
    }

    let facebook_link = "http://fb.com/treetopsadventure";
    if (this.state.park_id !== null && this.state.park_id === 6) {
        facebook_link = "https://www.facebook.com/hollybank.adventures";
    } else if (this.state.park_id !== null && this.state.park_id === 9) {
        facebook_link = "https://www.facebook.com/nextlevelpark";
    }

    return (
      <React.Fragment>
        {modalContact}
        <Stepper step={step} park_id={this.state.park_id} parks={this.state.parks} />
        <div className="container confirm" style={{ marginTop: 20 }}>
          <div className="confirm-thanks">
            Thanks {nameCustomer} <br/>
            for your request! <br/>
            <div className="sub">Your request has been sent</div>
          </div>
          <div className="confirm-box">
              <div className="mb-2">
                  <div className="confirm-details">
                    <div>
                        {namePark}
                        <br />
                        <i>
                            <FaUserAlt />
                        </i>{" "}{participants}
                    </div>
                    <div>{valDate} in the {preference}<br /><span></span></div>
                  </div>
              </div>
         </div>
         <br />
         <div className="confirm-text">
            A confirmation email and waiver form has been sent to <span>{emailCustomer}</span>. 
            <br />
            <br />
            Please <span>{contactButton}</span> if you have any questions about your booking. 
            <br />
            <br />
            <a href={facebook_link}><FaFacebook /></a>
            <a href="https://staffwildropes.taronga.com.au//"><FaHome /></a>
         </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ConfirmRequest;
