import React, { Component } from 'react';
import {Button} from "reactstrap";
import { FaFacebook, FaHome } from "react-icons/fa";
import StepperPayLink from '../Layout/StepperPayLink';
import { api_create_booking_step4_check } from "../../api/api";
import { colorSetProperty, setGoogleFont, setTitle } from "../../helpers/helpers";
import axiosRetry from 'axios-retry';
import axios from "axios";

class PaymentStatus extends Component {
    state ={
        loading: true,
        status: false,
        booking_id: '',
        name: '',
        pay_amount: 0,
        error_message: '',
        payment_name: '',
        payment_transaction_number: 0,
        total_amount: 0,
        amount_paid: 0,
        amount_due: 0,
        parks: null,
        updatedFont: false,
        updatedTitle: false,
        account: {
          saleType: false
        }
    }
    componentDidMount(){
        const booking_id = this.props.match.params.booking_id
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let windcaveSession = false
        // Full Page HPP
        if (urlParams.get('sessionId') != null) {
            windcaveSession = urlParams.get('sessionId')
            const client = axios.create({ baseURL: api_create_booking_step4_check, headers: {'Content-Type': 'application/json'} });
            axiosRetry(client, { retries: 30, retryDelay: (retryCount) => {
                return retryCount * 750;
            } });
            let parameters = {
              booking_id: booking_id,
              windcave_session: windcaveSession,
              pay_amount: '',
            };

            client.get("/check", { params: parameters }).then( ({data}) => {
            if (Object.keys(data).length > 0) {
                let booking_data = data.data[0]
                this.setState({
                    loading: false,
                    booking_id: booking_data.id,
                    name: booking_data.name,
                    pay_amount: JSON.parse(window.localStorage.getItem('pay_amount')),
                    error_message: booking_data.error,
                    amount_due: parseFloat(booking_data.amount_due),
                    total_amount: parseFloat(booking_data.amount),
                    amount_paid: parseFloat(booking_data.amount_paid),
                    park_id: booking_data.location_id[0],
                    status: true,
                    parks: JSON.parse(window.localStorage.getItem('parks')),
                    account: {
                        saleType: booking_data.account_sale_type
                    } 
                });
                if (data.errorMsg) {
                    this.setState({
                        error_message: data.errorMsg,
                        reco: data.reco,
                        status: false,
                    })
                }
            }
            })
        }
        // Iframe
        else {
            let booking_data = JSON.parse(window.localStorage.getItem('booking_data'))
            let payment_info = JSON.parse(window.localStorage.getItem('payment_info'))
            let account_sale_type = window.localStorage.getItem('account_sale_type')
            let amount_info = JSON.parse(window.localStorage.getItem('amount_info'))
            let parks = JSON.parse(window.localStorage.getItem('parks'))
            let status = false
            if (booking_data.success_page === 'true') {
                status = true
            }
            this.setState({
                loading: false,
                status: status,
                booking_id: booking_data.id,
                name: booking_data.name,
                pay_amount: parseFloat(booking_data.pay_amount),
                error_message: booking_data.error,
                payment_name: payment_info.name,
                payment_transaction_number: payment_info.transaction_number,
                amount_due: parseFloat(amount_info.amount_due),
                total_amount: parseFloat(amount_info.total_amount),
                amount_paid: parseFloat(amount_info.amount_paid),
                parks: parks,
                account: {
                    saleType: account_sale_type
                } 
            })
        }
    }

    componentDidUpdate() {
    // Call Customize
        if (this.state.parks) {
            setTimeout(() => {
                this.customizeColor(this.state.parks);
                if (this.state.updatedTitle == false) {
                    setTitle(this.state.parks[0].name);
                    this.setState({ updatedTitle: true })
                }
                // - custom font -
                let snipFont = this.state.parks.t_google_font_snippets + ' <style> body {' + this.state.parks.t_font_to_use + '} </style>';
                if (snipFont && this.state.updatedFont == false) {
                  setGoogleFont(snipFont);
                  this.setState({ updatedFont: true })
                }
            }, 500);
        }
    }
  
    customizeColor(park) {
      // Customize color
      const colors = {
          'color1': park.t_color1,
          'color2': park.t_color2,
          'color3': park.t_color3,
          'color4': park.t_color4,
      }
      colorSetProperty(colors);
    }

    descFailed = () => (
        <div>
            You have not been charged, you may re-use this <a href={window.location.protocol + '/#/booking_payment?booking_id=' + this.state.booking_id + '&amount=' + this.state.pay_amount}>link</a> to try again
        </div>
    ) 
    
    backtowebsite() {
        // Get the park id
        
        let { parks } = this.state;
        parks = parks[0]
        let park_name = parks.name.toLowerCase();
        if (parks) {
        window.location.href = parks.website
        }else {
        window.location.href = process.env.REACT_APP_WEB_HOME
        }
    }

    render() {
        let status = this.state.status
        const headerSuccess= 'Thank you for your payment'
        const headerFailed= 'Payment Failed'
        const codeFailed= 'Your payment failed with error code'
        const messageFailed = '(' + this.state.reco + ') ' + this.state.error_message
        const descSuccess='A confirmation email has been sent to the email address on file for this booking'
        const step = [true,true]

        let facebook_link = null;
        let website_link = null;
        let contact_link = null;
        if (this.state.parks !== null) {
            facebook_link = this.state.parks[0].facebook_link;
            website_link = this.state.parks[0].website;
            contact_link = this.state.parks[0].contact_us_link;
        }

        return (
        <div className={this.state.loading? 'loading': ''}>
            <StepperPayLink step={step} parks={this.state.parks} />
            <div className='container' style={{ marginTop: 30 }}>
                <div className="container confirm" style={{ marginTop: 20 }}>
                    <div className="confirm-thanks">
                        {status? headerSuccess : headerFailed}
                        {/* <div className="sub">
                            {status?'':`${codeFailed}`}
                        </div> */}
                        <div className="sub">
                            {status?'': <p style={{color: 'red'}}>{messageFailed}</p>}
                        </div>
                        <div className="confirm-paylink-box">
                            <div className="col col-xs-12 confirm-details">
                                <div className="row">
                                    <div className="col-md-12 title">BOOKING DETAILS</div>
                                </div>
                                {/* Booking Reference */}
                                <div className="row">
                                    <div className="col-md-6 label-detail">
                                        <span className="text-left">
                                          Booking Reference
                                        </span>
                                    </div>
                                    <div className="col-md-6 price-qty text-right">
                                        {this.state.name}
                                    </div>
                                </div>
                                {/* Total booking amount */}
                                {
                                    !['agent_deposit', 'agent_prepaid'].includes(this.state.account.saleType) ?
                                    <div className="row">
                                        <div className="col-md-6 label-detail">
                                            <span className="text-left">
                                            Total Booking Amount
                                            </span>
                                        </div>
                                        <div className="col-md-6 price-qty text-right">
                                            ${this.state.total_amount.toFixed(2)}
                                        </div>
                                    </div>
                                    :
                                    <>
                                    </>
                                }
                                {/* Amount paid */}
                                {
                                    !['agent_deposit', 'agent_prepaid'].includes(this.state.account.saleType) ?
                                    <div className="row ">
                                        <div className="col-md-6 label-detail">
                                            <span className="text-left">
                                            Amount Paid
                                            </span>
                                        </div>
                                        <div className="col-md-6 price-qty text-right">
                                            ${this.state.amount_paid.toFixed(2)}
                                        </div>
                                    </div>
                                    :
                                    <>
                                    </>
                                }
                                <hr />
                                {/* Amount outstanding */}
                                <div className="row">
                                    <div className="col-md-6 label-detail">
                                        <b>Amount Outstanding</b>
                                    </div>
                                    <div className="col-md-6 price-qty text-right">
                                        <b>${this.state.amount_due.toFixed(2)}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="confirm-text">
                        {status? `${descSuccess}` : this.descFailed()}
                        <br /><br />
                        Please <span><a href={contact_link}>contact the park</a></span> if you have any questions about your booking. 
                        <br /><br />
                        <Button className="btn btn-confirmation" onClick={() => this.backtowebsite()}>
                          Go back to the website
                        </Button>
                        <br /><br />
                        <a href={facebook_link}><FaFacebook /></a>
                        <a href={website_link}><FaHome /></a>         
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default PaymentStatus;
