import React, { Component } from 'react';
import {Button} from "reactstrap";
import { FaFacebook, FaHome } from "react-icons/fa";
import StepperPayLink from '../Layout/StepperPayLink';
import { api_pay_voucher_check, api_park_cache } from "../../api/api";
import { colorSetProperty, setGoogleFont, setTitle } from "../../helpers/helpers";
import axiosRetry from 'axios-retry';
import axios from "axios";

class VoucherPaymentStatus extends Component {
    state ={
        loading: true,
        status: false,
        voucher_order_id: '',
        name: '',
        pay_amount: 0,
        error_message: '',
        total_amount: 0,
        amount_paid: 0,
        amount_due: 0,
        parks: null,
        updatedFont: false,
        updatedTitle: false,
        reco: false,
        voucher_ids: [],
    }

    getParkData = (park_id) => {
        axios.get(api_park_cache).then(({ data }) => {
            let { result } = data;
            let res = result.filter(park => park.id == park_id);
            this.setState({
                parks:res
            })
        })
    }
    
    componentDidMount(){
        const voucher_order_id = this.props.match.params.voucher_code;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let windcaveSession = false
        // FOR FULL PAYMENT HPP
        if (urlParams.get('sessionId') != null) {
            windcaveSession = urlParams.get('sessionId')
            const client = axios.create({ baseURL: api_pay_voucher_check, headers: {'Content-Type': 'application/json'} });
            axiosRetry(client, { retries: 30, retryDelay: (retryCount) => {
            return retryCount * 750;
            } });
            let parameters = {
                voucher_order_id: voucher_order_id,
                windcave_session: windcaveSession,
            };
            

            client.get("/check", { params: parameters }).then( ({data}) => {
            console.log(data)
            if (Object.keys(data).length > 0) {
                this.getParkData(data.park)
                this.setState({
                    // park_id: data.voucher_ids[0].park_id,
                    voucher_order_id: data.voucher_order.id,
                    amount_due: parseFloat(data.voucher_order.amount_due),
                    total_amount: parseFloat(data.voucher_order.total_amount),
                    voucher_ids: data.voucher_ids,
                    name: data.voucher_order.name,
                    amount_paid: parseFloat(data.voucher_order.amount_paid),
                    pay_amount: JSON.parse(window.localStorage.getItem('pay_amount')),
                    amount: parseFloat(data.voucher_order.amount),
                    loading: false,
                    status: data.success,
                    parks: JSON.parse(window.localStorage.getItem('parks')),
                });
                if (!this.state.status) {
                    this.setState({
                        error_message: data.errorMsg,
                        reco: data.reco,
                    })
                }
            }
            })
        }
        // FOR IFRAME
        else {
            let voucher_data = JSON.parse(window.localStorage.getItem('voucher_data'))
            let voucher_ids = JSON.parse(window.localStorage.getItem('voucher_ids'))
            let payment_info = JSON.parse(window.localStorage.getItem('payment_info'))
            let amount_info = JSON.parse(window.localStorage.getItem('amount_info'))
            let parks = JSON.parse(window.localStorage.getItem('parks'))
            let status = false
            if (voucher_data.success_page === 'true') {
                status = true
            }
            this.setState({
                loading: false,
                status: status,
                voucher_order_id: voucher_data.id,
                name: voucher_data.name,
                pay_amount: parseFloat(voucher_data.pay_amount),
                error_message: voucher_data.error,
                payment_name: payment_info.name,
                payment_transaction_number: payment_info.transaction_number,
                amount_due: parseFloat(amount_info.amount_due),
                total_amount: parseFloat(amount_info.total_amount),
                amount: parseFloat(amount_info.amount),
                amount_paid: parseFloat(amount_info.amount_paid),
                voucher_ids: voucher_ids,
                parks: parks
            })
        }
    }

    componentDidUpdate() {
    // Call Customize
        if (this.state.parks) {
            setTimeout(() => {
                this.customizeColor(this.state.parks);
                if (this.state.updatedTitle == false) {
                    setTitle(this.state.parks[0].name);
                    this.setState({ updatedTitle: true })
                }
                // - custom font -
                let snipFont = this.state.parks.t_google_font_snippets + ' <style> body {' + this.state.parks.t_font_to_use + '} </style>';
                if (snipFont && this.state.updatedFont == false) {
                  setGoogleFont(snipFont);
                  this.setState({ updatedFont: true })
                }
            }, 500);
        }
    }
  
    customizeColor(park) {
      // Customize color
      const colors = {
          'color1': park.t_color1,
          'color2': park.t_color2,
          'color3': park.t_color3,
          'color4': park.t_color4,
      }
      colorSetProperty(colors);
    }

    descFailed = () => (
        <div>
            You have not been charged, you may re-use this <a href={window.location.protocol + '/#/voucher_payment?voucher_order_id=' + this.state.voucher_order_id + '&amount=' + this.state.pay_amount}>link</a> to try again
        </div>
    ) 
    
    backtowebsite() {
        // Get the park id
        let { parks } = this.state;
        parks = parks[0]
        let park_name = parks.name.toLowerCase();
        if (parks) {
        window.location.href = parks.website
        }else {
        window.location.href = process.env.REACT_APP_WEB_HOME
        }
    }

    render() {
        let status = this.state.status
        const headerSuccess= 'Thank you for your payment'
        const headerFailed= 'Payment Failed'
        const codeFailed= 'Your payment failed with error code'
        const messageFailed= '(' + this.state.reco + ') ' + this.state.error_message
        const descSuccess='A digital voucher has been sent to the email address specified.'
        const step = [true,true]
        let bonus = this.state.total_amount - this.state.amount;
        let facebook_link = null;
        let website_link = null;
        let contact_link = null;
        if (this.state.parks !== null) {
            facebook_link = this.state.parks[0].facebook_link;
            website_link = this.state.parks[0].website;
            contact_link = this.state.parks[0].contact_us_link;
        }

        return (
        <div className={this.state.loading? 'loading': ''}>
            <StepperPayLink step={step} parks={this.state.parks} />
            <div className='container' style={{ marginTop: 30 }}>
                <div className="container confirm" style={{ marginTop: 20 }}>
                    <div className="confirm-thanks">
                        {status? headerSuccess : headerFailed}
                        {/* <div className="sub">
                            {status?'':`${codeFailed}`}
                        </div> */}
                        <div className="sub" style={{color: 'red'}}>
                            {status?'':`${messageFailed}`}
                        </div>
                        <div className="confirm-paylink-box">
                            <div className="col col-xs-12 confirm-details">
                                <div className="row">
                                    <div className="col-md-12 title">Voucher DETAILS</div>
                                </div>

                                {/* Voucher Details */}
                                <div className="row">
                                {
                                    this.state.voucher_ids.map((voucher, index) => {
                                        // voucher_list.push('{"voucher_code":" ' + voucher.code + '", "voucher_value":"' + parseFloat(voucher.value).toFixed(2) + '"}')
                                        return (
                                            <>
                                                <div className="col-md-6 label-detail">
                                                    <span className="text-left ml-5">
                                                        {voucher.code == false ? 'DraftVoucher' + ++index : voucher.code}
                                                    </span>
                                                </div>
                                                <div className="col-md-6 price-qty text-right">
                                                    ${parseFloat(voucher.value).toFixed(2)}
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                </div>
                                <hr></hr>
                                {/* Amount paid */}
                                <div className="row ">
                                    <div className="col-md-6 label-detail">
                                        <span className="text-left">
                                          Total Paid
                                        </span>
                                    </div>
                                    <div className="col-md-6 price-qty text-right">
                                        ${this.state.amount_paid.toFixed(2)}
                                    </div>
                                </div>
                                {/* Total Voucher amount */}
                                <div className="row">
                                    <div className="col-md-9 label-detail">
                                        <span className="text-left">
                                          {bonus > 0 ? "Voucher Total With Bonus Value (+$" + bonus + ")" : "Voucher Total Value"}
                                        </span>
                                    </div>
                                    <div className="col-md-3 price-qty text-right">
                                        ${this.state.total_amount.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="confirm-text">
                        {status? `${descSuccess}` : this.descFailed()}
                        <br /><br />
                        Please <span><a href={contact_link}>contact the park</a></span> if you have any questions about your voucher purchase. 
                        <br /><br />
                        <Button className="btn btn-confirmation" onClick={() => this.backtowebsite()}>
                          Go back to the website
                        </Button>
                        <br /><br />
                        <a href={facebook_link}><FaFacebook /></a>
                        <a href={website_link}><FaHome /></a>         
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default VoucherPaymentStatus;
