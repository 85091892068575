import React, { Component } from "react";
import {Button, Input} from "reactstrap";
import StepperVoucher from "../../Layout/StepperVoucher";
import { FaFacebook, FaHome } from "react-icons/fa";
import axios from "axios";
import { api_pay_voucher_check, api_get_windcave_session_voucher, api_park_cache, api_customer_feedback } from "../../../api/api";
import NumberFormat from "react-number-format";
import { colorSetProperty, setGoogleFont, setHeadTag, unsetFooter, setTitle } from "../../../helpers/helpers";
import axiosRetry from 'axios-retry';

var timeSpinner = ""
class SuccessPage extends Component {
  state = {
    data:[],
    customer:{},
    parks: null,
    park_id: null,
    park_data: null,
    updatedFont: false,
    updatedHeadTag: false,
    updatedTitle: false,
    voucher_order: false,
    isLoading: true,
    errorMsg: false,
    errorCounter: 0,
    reco: false,
    voucher_ids: [],
    feedback: "",
    isSendFeedback: false
  };
  componentDidMount() {
    const voucher_order_id = this.props.match.params.id_voucher;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let vc_id = [];
    let windcaveSession = false
    if (urlParams.get('sessionId') != null) {
      windcaveSession = urlParams.get('sessionId')
    }
    const client = axios.create({ baseURL: api_pay_voucher_check, headers: {'Content-Type': 'application/json'} });
    axiosRetry(client, { retries: 30, retryDelay: (retryCount) => {
      return retryCount * 750;
    } });
    let parameters = {
      voucher_order_id: voucher_order_id,
      windcave_session: windcaveSession,
    };

    client.get("/check", { params: parameters }).then( ({data}) => {
      if (Object.keys(data).length > 0) {
        data.voucher_ids.map(d => {
            vc_id.push(d.id)
        })
        this.setState({
          customer: data.customer,
          data: data.voucher_ids,
          voucher_ids: vc_id,
          park_id: data.voucher_ids[0].park_id,
          voucher_order: data.voucher_order,
          isLoading: false,
        });
        if (data.errorMsg) {
          this.setState({
            errorMsg: data.errorMsg,
            reco: data.reco
          })
        }
      }
    })
  }

  componentDidUpdate() {
    let { park_data } = this.state;
    if (park_data) {
      // Call set property
      this.customizeColor(park_data)
      if (this.state.updatedTitle == false) {
        setTitle(park_data.name);
        this.setState({ updatedTitle: true })
      }
      // - custom font -
      let snipFont = park_data.t_google_font_snippets + ' <style> body {' + park_data.t_font_to_use + '} </style>';
      if (snipFont && this.state.updatedFont == false) {
        setGoogleFont(snipFont);
        this.setState({ updatedFont: true })
      }
       // - head tag - 
       let snipheadtag = park_data.t_embed_head_tag;
       if (snipheadtag && this.state.updatedHeadTag == false) {
         setHeadTag(snipheadtag);
         this.setState({updatedHeadTag: true})
       }
    }else {
      this.getParkData();
      // - unset footer -
      unsetFooter();
    }
  }

  getParkData() {
    if (this.state.park_id) {
      axios.get(api_park_cache).then(({ data }) => {
        let park_id = this.state.park_id
        let park_data = data.result.filter(park => park.id == park_id)
        this.setState({
          parks: data.result,
          park_data: park_data[0]
        })
      }).catch(({ error }) => {});
    }
  }

  customizeColor(park) {
    // Customize color
    const colors = {
        'color1': park.t_color1,
        'color2': park.t_color2,
        'color3': park.t_color3,
        'color4': park.t_color4,
    }
    colorSetProperty(colors);
  }

  renderVoucher = () =>{
    let bonus = this.state.voucher_order.total_amount - this.state.voucher_order.amount; 
    let voucher = []
    // eslint-disable-next-line
    this.state.data.map(d=>{
      voucher.push(
        <tr>
          <td>{d.recipient_name}</td>
          <td>{d.name}</td>
          <td>
          <NumberFormat
                value={d.voucher_value}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={value => <span>{value}.00</span>}
              />
            </td>
        </tr>
      )
    })
    
    let parkName = null;
    if (this.state.park_data) {
      parkName = this.state.park_data.name
    }
    return(
      <div className="confirm-box">
        <div className={`col col-xs-12 confirm-details ${this.state.isLoading ? "loading" : ''}  `}>
          <div className="row">
              <div className="col-md-12 title">Gift Voucher</div>
              <div className="col-md-12" style={{fontWeight:400}}>Location: {parkName}</div>
          </div>

          <div style={{fontWeight:400,marginTop:"12px"}}>Gift Voucher Details: </div>
          <div className="row">
            {
                this.state.data.map((voucher, index) => {
                    // voucher_list.push('{"voucher_code":" ' + voucher.code + '", "voucher_value":"' + parseFloat(voucher.value).toFixed(2) + '"}')
                    return (
                        <>
                            <div className="col-md-6 label-detail">
                                <span className="text-left ml-3">
                                  {voucher.code == false ? 'DraftVoucher' + ++index : voucher.code}
                                </span>
                            </div>
                            <div className="col-md-6 price-qty text-right">
                                ${parseFloat(voucher.voucher_value).toFixed(2)}
                            </div>
                        </>
                    )
                })
            }
          </div>
          <hr></hr>
          {/* Amount paid */}
          <div className="row ">
              <div className="col-md-6 label-detail">
                  <span className="text-left">
                    Total Paid
                  </span>
              </div>
              <div className="col-md-6 price-qty text-right">
                  ${parseFloat(this.state.voucher_order.amount_paid).toFixed(2)}
              </div>
          </div>
          {/* Total Voucher amount */}
          <div className="row">
              <div className="col-md-9 label-detail">
                  <span className="text-left">
                    { bonus > 0 ? "Voucher Total With Bonus Value (+$" + bonus + ")"  : "Total Voucher Value" }
                    
                  </span>
              </div>
              <div className="col-md-3 price-qty text-right">
                  ${parseFloat(this.state.voucher_order.total_amount).toFixed(2)}
              </div>
          </div>
        </div>
      </div>
    )
    
  }
  backtowebsite() {
    // Get the park id
    let { park_data } = this.state;
    let park_name = park_data.name.toLowerCase();
    if (park_name.match(/next.*/)) {
      window.location.href = park_data.website
    }else {
      window.location.href = process.env.REACT_APP_WEB_HOME
    }
  }

  postToApi = (url, param) => {
    let params = {
      jsonrpc: "2.0",
      method: "call",
      id: null,
      params: param
    };
    return axios.post(url, params, {
      headers: { "Content-Type": "application/json;charset=utf-8" }
    });
  }

  fetchNewSession = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let hasSafari = userAgent.match(/Safari/i);
    let hasOtherBrowser = /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox|GSA/i.test(userAgent)
    let isAppleVendor = /Apple/i.test(navigator.vendor);
    let iosDevice = /iPad|iPhone|iPod/.test(userAgent)
    let isMac = /Macintosh/.test(userAgent)
    let pay_method = ['card']

    // Apple Pay only works on ios device and safari
    if ( (iosDevice && hasSafari && isAppleVendor && !hasOtherBrowser) || (isMac && hasSafari && !hasOtherBrowser) ) {
      this.setState({isIOS: true})
      pay_method.push('applepay');
    }
    // Google pay works on most of the android, browser and mac
    if (!iosDevice || ( isMac && hasOtherBrowser )) {
      pay_method.push('googlepay');
    }
    this.postToApi(api_get_windcave_session_voucher, {
      'total_amount': parseFloat(this.state.voucher_order.amount).toFixed(2),
      'voucher_code': this.state.voucher_ids,
      'voucher_order_id': this.state.voucher_order.id,
      'previous_windcave_session': this.state.previousWindcaveSession,
      'windcave_session': this.state.windcaveSession,
      'exp_from_record': true,
      'full_page': true,
      'payment_method': pay_method,
    })
    .then(({ data }) => {
      if ("result" in data) {
        if ('errors' in data.result) {
          if (data.result.errors[0]['target'] == "expires"){
            alert('Your Session Has Expired, No transaction has been made')
            window.location.href = '/#/voucher/0'
          }
        }

        const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
        window.location.href = windcaveRef
      }
    })
  }

  sendFeedback = () => {
    if(this.state.feedback !== ""){
      try {
        let params = {
          jsonrpc: "2.0",
          method: "call",
          id: null,
          params: {
            booking_id: this.props.match.params.id_voucher,
            value: this.state.feedback
          }
        };
        let res = axios.post(api_customer_feedback, params, {headers: { "Content-Type": "application/json;charset=utf-8" }})
        res.then(({ data }) => {
          if (data['error']) {
            alert('error')
          } else {
            this.setState({
              isSendFeedback: true
            })
          }
        })
        .catch(err => {
          this.props.toggleLoading(false);
          console.log(err);
        });

      } catch(err) {
        console.log(err)
      }
    }
  };

  renderSuccessMsg(facebook_link, website_link) {
    return (
      <>
        A digital voucher has been sent to the email address specified.
        <br/>
        <br/>
        Please{" "}
        <span>
          <a href={'https://taronga.org.au/contact'}>
            contact the park
          </a>
        </span>{" "}
        if you have any questions about your voucher purchase.
        <br />
        <br />
          <Button className="btn btn-confirmation" onClick={() => this.backtowebsite()}>
            View Website
          </Button>
        <br />
        <br />
        <a href={facebook_link}>
          <FaFacebook />
        </a>
        <a href={website_link}>
          <FaHome />
        </a>
      </>
    )
  }

  renderErrorMsg(facebook_link, website_link, contact_link=false) {
    return (
      <>
        The transaction has been cancelled
        <br/>
        <br/>
        No payment has been processed.
        <br />
        <br />
          <Button className="btn btn-confirmation" onClick={() => this.fetchNewSession()}>
            Retry Payment
          </Button>
        <br />
        <br />
        <a href={facebook_link}>
          <FaFacebook />
        </a>
        <a href={website_link}>
          <FaHome />
        </a>
      </>
    )
  }

  render() {
    let step = [
      true,true,true,true,true
    ];
    let email = "mailto:"+this.state.customer.email

    let facebook_link = null;
    let website_link = null;
    let parkName = null;
    let contact_link = null;
    if (this.state.park_data) {
      facebook_link = this.state.park_data.facebook_link
      website_link = this.state.park_data.website
      parkName = this.state.park_data.name
    }
    return (
      <React.Fragment>
        <StepperVoucher step={step} park_id={this.state.park_id} parks={this.state.parks} />
        <div className="container confirm" style={{ marginTop: 20 }}>
          { this.state.data.length ? (
            <>
              <div className="confirm-thanks">
                {this.state.errorMsg ? 'Transaction Failed': 'Thank you for purchasing a gift of adventure'}
                <br />
              </div>
              <div style={{marginTop: "16px", marginBottom: "16px"}}>
                {this.state.isSendFeedback ? "Thanks for your feedback" : (
                  <>
                    <Input
                      type="select"
                      name="park"
                      ref="park"
                      onChange={e =>
                        this.setState({
                          feedback: e.target.value
                        })
                      }
                      readOnly>
                        <option value="">How do you hear about us ?</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Ads">Ads</option>
                        <option value="A friend">A friend</option>
                        <option value="Family">Family</option>
                        <option value="Returning customer">Returning customer</option>
                    </Input>
                    <Button
                          onClick={this.sendFeedback}
                          className="btn btn-confirmation"
                          style={{width: "100%", marginTop:"12px"}}
                        >
                        Submit
                    </Button>
                  </>
                  )
                }
              </div>
              {this.state.errorMsg ? <p style={{color: 'red'}}>({this.state.reco}) {this.state.errorMsg}</p>  : ''}
            </>
          ) : (
            ""
          )}
          {this.state.errorMsg ? '' : this.renderVoucher() }
          <br />
          <div className="confirm-text">
            { this.state.errorMsg ? this.renderErrorMsg(facebook_link, website_link) : this.renderSuccessMsg(facebook_link, website_link) }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SuccessPage;
