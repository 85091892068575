import React, { Component } from 'react';
import {FaTimes, FaArrowLeft, FaEdit, FaTrash} from "react-icons/fa";
import Moment from "moment";
import {
    CardBody,
    Card,
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    InputGroupAddon,
    Popover,
    PopoverBody
  } from "reactstrap";

let fields = [];
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

class MinorParticipantCard extends Component {
    state = {
        minorCounts: 0,
        minorParticipant: [],
        fullName: null,
        dateOfBirth: null,
        year: null,
        month: null,
        age: null,
        isMedicalCondition: null,
        medicalConditionReason: null,
        isEditMinor: 0,
        error: {
            validation: false,
            fullName: false,
            year: false,
            month: false,
            age: false,
            isMedicalCondition: false,
            medicalConditionReason: false,
        }
    }

    editMinor(index) {
        let state = { ...this.state };
        let minorParticipants = state.minorParticipant[index]
        
        state.isEditMinor = index + 1;
        state.fullName = minorParticipants.fullName;
        state.month = minorParticipants.month;
        state.year = minorParticipants.year;
        state.age = minorParticipants.age;
        state.isMedicalCondition = minorParticipants.isMedicalCondition;
        state.medicalConditionReason = minorParticipants.medicalConditionReason;

        this.setState(state)
    }

    removeMinor(index) {
        let state = { ...this.state };
        state.minorParticipant.splice(index)
        state.minorCounts -= 1

        this.setState(state)
    }

    componentDidUpdate(prevProp, prevState) {
        if ((this.state.year != null && (this.state.year != prevState.year) && this.state.month != null) ||
            (this.state.year != null && this.state.month != null && (this.state.month != prevState.month))) {
            let input_month = parseInt(this.state.month)
            let session_month = parseInt(Moment(this.props.booking.session_date).month())
            let age = Moment().year() - this.state.year

            if (input_month >= session_month) {
                this.setState({age: age - 1})
            }
            else {
                this.setState({age: age})
            }
        }
    }

    getMonthDropdownContent() {
        const content = months.map((key, index) => {
            return (
                <option value={index + 1}>
                    {key}
                </option>
            )
        })
        return content
    }

    getYearDropdownContent() {
        const current_year = new Date().getFullYear()
        const years = []
        for (var i = current_year, i_len = 1930; i >= i_len; i--) {
            years.push(i)
        }
        const content = years.map(key => {
            return (
                <option value={key}>
                    {key}
                </option>
            )
        })
        return content
    }

    saveMinorParticipant = value => { 
        let minors = this.state.minorParticipant
        let medicalConditionReason;
        if (this.state.isMedicalCondition == 0) {
            medicalConditionReason = "";
        }
        else {
            medicalConditionReason = this.state.medicalConditionReason
        }

        if (this.state.isEditMinor > 0) {
            minors[this.state.isEditMinor - 1] = {
                fullName: this.state.fullName,
                year: this.state.year,
                month: this.state.month,
                age: this.state.age,
                isMedicalCondition: this.state.isMedicalCondition,
                medicalConditionReason: medicalConditionReason,
            }
            this.setState({ 
                minorParticipant: minors,
                isEditMinor: false
            })
        }
        else {
            minors.push({
                fullName: this.state.fullName,
                year: this.state.year,
                month: this.state.month,
                age: this.state.age,
                isMedicalCondition: this.state.isMedicalCondition,
                medicalConditionReason: medicalConditionReason,
            })
            this.setState({ 
                minorCounts: this.state.minorCounts + 1,
                minorParticipant: minors,
                isEditMinor: false
            })
        }
    }

    getMinorParticipantContent() {
        const waiverContent = this.state.minorParticipant.map((pax, index) => {
            return (
                <tr>
                    <td>
                        {pax.fullName}
                    </td>
                    <td>{pax.month}/{pax.year}</td>
                    <td>{pax.medicalConditionReason}</td>
                    <td style={{ borderTop: "0px None" }}>
                        <span onClick={() => this.editMinor(index)}>
                            <FaEdit />
                        </span>
                        
                    </td>
                    <td style={{ borderTop: "0px None" }}>                                            
                        <FaTrash onClick={() => this.removeMinor(index)}/>
                    </td>
                </tr>
            )
        })
        return waiverContent
    }

    handleChange(e) {
        let value;
        
        fields[e.target.name] = e.target.value.length;

        if (e.target.type == 'checkbox') {
            value = e.target.checked
        }
        else if(e.target.type == 'radio') {
            if (parseInt(e.target.value) == 1) {
                value = true
            } 
            else {
                value = false
            }
        }
        else {
            value = e.target.value;
        }
        this.setState({ [e.target.name]: value });
    }

    validateWaiver(next=false) {
        let state = { ...this.state };
        let error = false
        
        state.error.fullName = false
        state.error.year = false
        state.error.month = false
        state.error.age = false
        state.error.isMedicalCondition = false
        state.error.medicalConditionReason = false
        if (!this.state.fullName) {
            state.error.fullName = "Full Name is Required"
            error = true
        }
        else {
            if (this.state.fullName.split(' ').length == 1) {
                state.error.fullName = "Please complete your full name"
                error = true
            }
        }
        if (!this.state.year) {
            state.error.year = "Year is Required"
            error = true
        }
        if (!this.state.month) {
            state.error.month = "Month is Required"
            error = true
        }
        if (!this.state.age) {
            state.error.age = "Age is Required"
            error = true
        }

        if (this.state.age >= 18) {
            state.error.age = "Oops! Please enter the correct date of birth for 17yrs and Under participant."
            error = true
        }
        if (this.state.isMedicalCondition == null) {
            state.error.isMedicalCondition = "Medical Condition is Required"
            error = true
        }
        if (this.state.isMedicalCondition && !this.state.medicalConditionReason) {
            state.error.medicalConditionReason = "Medical Condition Reason is Required"
            error = true
        }
        if (!error) {
            state.fullName = ""
            state.dateOfBirth = ""
            state.year = "."
            state.month = "."
            state.age = ""
            state.isMedicalCondition = ""
            state.medicalConditionReason = ""
            this.setState(state)
            this.saveMinorParticipant();
            if (next) {
                this.props.actionShow("SignWaiverShow")
                this.props.saveMinorParticipant(this.state.minorParticipant)
            }
        }
        else {
            if (next) {
                if (this.state.minorParticipant.length > 0) {
                    state.fullName = ""
                    state.dateOfBirth = ""
                    state.year = "."
                    state.month = "."
                    state.age = ""
                    state.isMedicalCondition = ""
                    state.medicalConditionReason = ""
                    state.error.fullName = false
                    state.error.year = false
                    state.error.month = false
                    state.error.age = false
                    state.error.isMedicalCondition = false
                    state.error.medicalConditionReason = false
                    this.props.actionShow("SignWaiverShow")
                    this.props.saveMinorParticipant(this.state.minorParticipant)
                }
            }
            this.setState(state)
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    validateMinorParticipants = () => {
        let state = { ...this.state };
        let error = false
        
        state.error.validation = false

        if (this.state.minorParticipant.length == 0) {
            state.error.validation = "Please fill out the minor participants"
            error = true
        }

        if (!error) {
            this.setState(state)
            this.props.saveMinorParticipant(this.state.minorParticipant)
        }
        else {
            this.setState(state)
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    getFormErrorContent() {
        return (
            <div class="errorTxt fs-subtitle">
                {   this.state.error.fullName ?
                    <div class="error">
                        {this.state.error.fullName}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.year ?
                    <div class="error">
                        {this.state.error.year}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.month ?
                    <div class="error">
                        {this.state.error.month}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.age ?
                    <div class="error">
                        {this.state.error.age}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.isMedicalCondition ?
                    <div class="error">
                        {this.state.error.isMedicalCondition}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.medicalConditionReason ?
                    <div class="error">
                        {this.state.error.medicalConditionReason}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.validation ?
                    <div class="error">
                        {this.state.error.validation}
                    </div>
                    :
                    <></>
                }
            </div>
        )
    }

    render() {
        const minorParticipantContent = this.getMinorParticipantContent()
        const FormErrorContent = this.getFormErrorContent()
        const MonthDropdownContent = this.getMonthDropdownContent()
        const YearDropdownContent = this.getYearDropdownContent()
        return (
            this.props.MinorParticipantShow ?
                <div>
                    <div className="form-card">
                        <FaTimes 
                            type='button'
                            className="close"
                            value="Cancel"
                            name="btn_cancel" id="btn_cancel"
                    />
                        <div className="title-content text-center">
                            <h2 className="fs-title"><span>{this.props.park.name}</span></h2>
                            <h4 className="fs-subtitle"><strong>Please provide the details of all participants aged 17 years and under</strong></h4>
                            <h4 className="fs-subtitle" style={{marginTop: "20px"}}>
                                Child
                                {
                                    this.state.isEditMinor > 0 ?
                                    <span id="count_child"> {this.state.isEditMinor}</span>
                                    :
                                    <span id="count_child"> {this.state.minorCounts + 1}</span>
                                }
                            </h4>
                            <div className="errorTxt fs-subtitle">
                                {FormErrorContent}
                            </div>
                        </div>
                        <div className="btn-group" style={{width: "100%", justifyContent: "center", alignItems: "center"}}>
                            <div className="container-minor col-md-6 full-responsive">
                                <div>
                                    <label for="fullName" className="col-sm-4 fs-label" style={{paddingLeft: "0px"}}>Full Name</label>
                                    <Input 
                                        type="text" 
                                        className={`form-control input-lg fs-input ${this.state.error.fullName ? 'form-error ' : ''}`}
                                        id="fullName" 
                                        name="fullName"
                                        value={this.state.fullName}
                                        onChange={(e) => {
                                            this.handleChange(e)
                                        }}
                                    />

                                    <div className="row justify-content-between" style={{ paddingLeft: "17px", paddingRight: "17px", marginBottom: '27px'}}>

                                        <div style={{ width: "40%" }}>
                                            <label for="month" style={{ paddingLeft: 0 }} className="fs-label">Birth Month</label>
                                            <select 
                                                type="number" 
                                                className={`form-control exclude adult-dob-field ${this.state.error.month ? 'form-error ' : ''}`}
                                                id="month" 
                                                data-toggle="tooltip" 
                                                data-placement="top" 
                                                data-html="true" 
                                                data-trigger="manual" 
                                                name="month" 
                                                value={this.state.month}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                            >
                                                <option value="." disabled selected>
                                                    ...
                                                </option>
                                                {MonthDropdownContent}
                                            </select>
                                        </div>
                                        <div style={{ width: "40%" }}>
                                            <label for="year" style={{ paddingLeft: 0 }} className="fs-label">Birth Year</label>
                                            <select
                                                type="number" 
                                                className={`form-control exclude adult-dob-field ${this.state.error.year ? 'form-error ' : ''}`}
                                                id="year" 
                                                data-toggle="tooltip" 
                                                data-placement="top" 
                                                data-html="true" 
                                                data-trigger="manual" 
                                                name="year"
                                                value={this.state.year}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                            >
                                                <option value="." disabled selected>
                                                    ...
                                                </option>
                                                {YearDropdownContent}
                                            </select>
                                        </div>
                                        {/* <div style={{ width: "40%" }}>
                                            <label id="age" for="age" style={{paddingLeft: 0}} className="fs-label">Age</label>
                                            <Input 
                                                type="number" 
                                                className={`form-control exclude fs-input ${this.state.error.age ? 'form-error ' : ''}`}
                                                id="age" 
                                                name="age" 
                                                min="1"
                                                value={this.state.age}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}                                       
                                            />
                                        </div> */}
                                    </div>

                                    <label for="isMedicalCondition_min" className="col-sm-12 fs-label" style={{paddingLeft: 0, marginBottom: "9px"}}>Does this child suffer from any medical conditions?</label>
                                    <div className="row" style={{marginBottom: "10px"}}>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-4 radio-container" style={{ display: "flex", flexDirection: "row" }}>
                                                    Yes
                                                    <Input 
                                                        className="checkmark"
                                                        type="radio"
                                                        name="isMedicalCondition"
                                                        id="isMedicalCondition_yes"
                                                        style={{ marginTop: "10px" }}
                                                        value={1}
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 radio-container" style={{ display: "flex", flexDirection: "row" }}>
                                                    No
                                                    <Input 
                                                        className="checkmark"
                                                        type="radio"
                                                        name="isMedicalCondition"
                                                        id="isMedicalCondition_no"
                                                        style={{ marginTop: "10px" }}
                                                        value={0}
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom: "10px"}}>
                                        <div className="col-sm-8" style={{visibility: `${this.state.isMedicalCondition == 1 ? "visible": "hidden"}`}}>
                                            <Input 
                                                style={{marginBottom: "10px"}} 
                                                type="text" 
                                                className={`form-control`}
                                                id="medicalConditionReason" 
                                                name="medicalConditionReason" 
                                                placeholder="Please let us know" 
                                                value={this.state.medicalConditionReason}                                       
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-4" style={{top: "-1rem"}}>
                                            <button 
                                                id="add_child" 
                                                type="submit" 
                                                name="add_child" 
                                                className="action-button-previous flow-right btn-submit-minor" 
                                                value="Add Child" 
                                                onClick={(e) => {
                                                    this.validateWaiver()
                                                }}
                                            >
                                                Submit Child&nbsp;
                                                {
                                                    this.state.isEditMinor > 0 ?
                                                    this.state.isEditMinor
                                                    :
                                                    this.state.minorCounts + 1
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Input type="hidden" id="minorCounts" />
                        {
                            this.state.minorCounts >= 1 ?
                            <div className="row table-container-minor">
                            <div className="table-content">
                                <table id="minors" name="minors" className="table">
                                    <thead className="table-minor">
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Date of Birth</th>
                                            <th>Medical Condition</th>
                                        </tr>
                                    </thead>

                                    
                                    <tbody>
                                        {minorParticipantContent}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :
                        <></>
                        }
                    </div>
                    <button 
                        type="button" 
                        className="action-button-previous" 
                        value="Previous" 
                        onClick={() => this.props.actionShow("EmergencyContactShow")}
                    >
                        <FaArrowLeft/> Back</button>
                    <input 
                        id="submit_pi" 
                        type="submit" 
                        name="submit_pi" 
                        className="next action-button float-right" 
                        value="Next" 
                        onClick={(e) => {
                            e.preventDefault();
                            this.validateWaiver(true);
                            // this.validateMinorParticipants();
                        }}
                    />
                </div>
            :
                <></>
        );
    }
}
export default MinorParticipantCard
