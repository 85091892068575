import React, { Component } from 'react';
import {FaHandPointLeft, FaArrowLeft, FaEdit, FaTrash} from "react-icons/fa";
import axios from "axios";
import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
  } from "reactstrap";
import { api_get_park_waiver_content, api_sign_waiver } from '../../../api/api';
import SignatureCanvas from 'react-signature-canvas';

class SignWaiverCard extends Component {
    sigCanvasRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            park: null,
            waiver_content_1: null,
            waiver_content_2: null,
            waiver_content_3: null,
            waiver_content_4: null,
            waiver_content_5: null,
            openSignWaiver: false,
            signatureValue: '',
            top: 0,
            accept_sign: false,
        }
    
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    sign_waiver = () => {
        let state = { ...this.state };
        const params = {
            jsonrpc: "2.0",
            method: "call",
            params: {
                waiverID: this.props.waiverID,
                signature: this.state.signatureValue
            }
        };
        axios
        .post(api_sign_waiver, params, {
            headers: { "Content-Type": "application/json;charset=utf-8" }
        })
        .then(({ data }) => {
            this.props.toggleLoading(false);
            sessionStorage.setItem('color1', this.props.park.t_color1)
            sessionStorage.setItem('park_id', this.props.park.id)
            sessionStorage.setItem('park_name', this.props.park.name)
            sessionStorage.setItem('waiver_id', this.props.bookingID)
            window.location.href = "/#/waiver_kiosk/" +  this.props.park.id + "/" + this.props.waiverID + "/confirmation";
        })
          .catch(({ err }) => {});
    };

    componentDidUpdate(prevProp, prevState) {
        if ((this.state.waiver_content == null && this.props.SignWaiverShow != prevProp.SignWaiverShow) && this.props.park) {
            this.get_waiver_content()
        }
    }

    updateDimensions() {
        if (document.getElementById('waiver-content')) {
            let elem = document.getElementById('waiver-content')
            let scrollMax = elem.scrollHeight
            let positionScale = (480 / scrollMax)
            let iconPosition = elem.scrollTop * positionScale
            this.setState({
                top: iconPosition
            });
            if( elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight - 25)) {
                this.setState({
                    accept_sign: true
                });
            }
        }
    }

    get_waiver_content = () => {
        const params = {
          jsonrpc: "2.0",
          method: "call",
          params: {
            park_id: parseInt(this.props.park.id)
          }
        };
        axios
          .post(api_get_park_waiver_content, params, {
            headers: { "Content-Type": "application/json;charset=utf-8" }
          })
          .then(({ data }) => {
            this.setState({ 
                waiver_content_1: data.result.waiver_content_1,
                waiver_content_2: data.result.waiver_content_2,
                waiver_content_3: data.result.waiver_content_3,
                waiver_content_4: data.result.waiver_content_4,
                waiver_content_5: data.result.waiver_content_5
            });
          })
          .catch(({ err }) => {});
    };

    previousPage() {
        this.props.actionShow("MinorParticipantShow")
    }

    clearSignature = () => {
        this.sigCanvasRef.clear();
        this.setState({ signatureValue: '' });
    }

    signWaiver = () => {

        this.props.toggleLoading(true);
        this.sign_waiver()
        this.toggleOpenSignModal();
    }

    getData() {
        if (document.getElementById('waiver-content')) {
            document.getElementById('waiver-content').addEventListener("scroll", this.updateDimensions)
        }
        let waiver_content_1, waiver_content_2, waiver_content_3, waiver_content_4, waiver_content_5 = null

        if (this.state.waiver_content_1 != null) {
            waiver_content_1 = this.state.waiver_content_1
        }
        if (this.state.waiver_content_2 != null) {
            waiver_content_2 = this.state.waiver_content_2
        }
        if (this.state.waiver_content_3 != null) {
            waiver_content_3 = this.state.waiver_content_3
        }
        if (this.state.waiver_content_4 != null) {
            waiver_content_4 = this.state.waiver_content_4
        }
        if (this.state.waiver_content_5 != null) {
            waiver_content_5 = this.state.waiver_content_5
        }
        
        return {waiver_content_1, waiver_content_2, waiver_content_3, waiver_content_4, waiver_content_5}
    }

    toggleOpenSignModal = () => {
        this.setState({ openSignWaiver: !this.state.openSignWaiver })
    }

    getMinorParticipantContent(){
        const minorParticipantsContent = this.props.waiverPersonalInfo.minorParticipants.map(pax => {
            return (
                <tr>
                    <td className="pl-3">
                        <span>{pax.fullName}</span>
                    </td>
                    <td className="pl-3">
                        <span>{pax.month}/{pax.year}</span>
                    </td>
                    <td className="pl-3">
                        <span>{pax.age}</span>
                    </td>
                    {/* <td style={{ borderTop: "0px None" }}>
                        <FaEdit />
                    </td>
                    <td style={{ borderTop: "0px None" }}>                                            
                        <FaTrash />
                    </td> */}
                </tr>
            )
        })

        const minorWaiverContent = 
        <>
            <table className="table table-sm" style={{width: '50%'}}>
                <thead>
                    <tr>
                        <th className="pl-3">Full Name</th>
                        <th className="pl-3">Date of Birth</th>
                        <th className="pl-3">Age</th>
                        {/* <th className="pl-3"></th>
                        <th className="pl-3"></th> */}
                    </tr>
                </thead>
                <tbody>
                    {minorParticipantsContent}
                </tbody>
            </table>
        </>
        return minorWaiverContent
    }

    render() {
        const {waiver_content_1, waiver_content_2, waiver_content_3, waiver_content_4, waiver_content_5} = this.getData()
        let styles = {
            scrollPointer: {
                color: "red",
                position: "absolute",
                top: this.state.top + "px"
            }
        }
        return (
            this.props.SignWaiverShow ?

            <Form 
                onSubmit={e => {
                    this.signWaiver();
                }}
                className="waiver-personal-info"
            >
                <div className="form-card">
                    <button type="button" className="close btn btn-lg btn-light fa fa-times" name="btn_cancel" id="btn_cancel" data-toggle="modal" data-target="#leave_waiver_kiosk" value="Cancel">

                    </button>
                    <div className="title-content text-center">
                        <h2 className="fs-title"><span>{this.props.park.name}</span></h2>
                    </div>
                    <div style={{width:"100%", display: "flex", justifyContent: "center"}}>
                        <div className="title-content fs-subtitle" style={{width: "75%"}}>
                            <div className="row align-items-center">
                            <span style={{marginRight: "5px", marginLeft: "13px", fontSize: "16px"}}> Waiver for: </span>
                                <span style={{fontSize: "16px"}}>
                                    {this.props.customer.name}
                                </span>
                            </div>
                            <div className="row align-items-center">
                                <span style={{marginRight: "5px", marginLeft: "13px", fontSize: "16px"}}> Completed by: </span>
                                <span style={{fontSize: "16px"}}> 
                                    {this.props.waiverPersonalInfo.fullName}
                                </span>
                            </div>

                        </div>
                    </div>
                    <div style={{width:"100%", display: "flex", justifyContent: "center"}}>
                        <div className="mt16 clearfix" style={{paddingBottom: "30px", paddingRight: "15px", width: "12.5%"}}>
                        </div>
                        <div id="waiver-content" className="bg-light sign-waiver-content" style={{height: "500px", overflowY: "scroll", paddingRight: "15px", paddingLeft: "15px"}} >
                            <div dangerouslySetInnerHTML={ {__html: this.state.waiver_content_1} } />
                            <div dangerouslySetInnerHTML={ {__html: this.state.waiver_content_2} } />

                            <h6 className="detail-label">
                                Personal Detail: 
                                <button type="submit" name="edit_main" className="fa edit-button btn btn-link" value="Edit" onClick={ () => this.props.actionShow("PersonalInformationShow")}>
                                    Edit
                                </button>
                            </h6>

                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">Full Name : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.fullName}</span></span>
                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">DOB : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.month}/{this.props.waiverPersonalInfo.year}</span></span>
                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">Age : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.age}</span></span>
                            <br/>
                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">Street Address : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.address}</span></span>
                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">Suburb : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.suburb} </span></span>
                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">Postcode : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.postcode} </span></span>
                            <br/>
                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">Email Address : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.email} </span></span>
                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">Phone number : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.phone} </span></span>
                            
                            <br/><br/>

                            <h6 className="detail-label">
                                Emergency Contact Detail: 
                                <button type="submit" name="edit_contact" className="fa edit-button btn btn-link" value="Edit" onClick={ () => this.props.actionShow("EmergencyContactShow")}>
                                    Edit
                                </button>
                            </h6>

                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">
                                Full Name : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.emergencyName}</span>
                            </span>
                            <span style={{marginRight: "10px"}} className="d-block d-md-inline">
                                Phone Number : <span className="detail-label font-weight-bold">{this.props.waiverPersonalInfo.emergencyPhoneNumber}</span>
                            </span>

                            <div dangerouslySetInnerHTML={ {__html: this.state.waiver_content_3} } />
                            <div dangerouslySetInnerHTML={ {__html: this.state.waiver_content_4} } />

                            <h6 className="detail-label">Children Details:  
                                <button type="submit" name="edit_children" className="fa edit-button btn btn-link" value="Edit" onClick={ () => this.props.actionShow("MinorParticipantShow")}>
                                    Edit
                                </button>
                            </h6>
                            
                            {this.props.waiverPersonalInfo.minorParticipants.length > 0 ? this.getMinorParticipantContent() : ""}
                            
                            <div dangerouslySetInnerHTML={ {__html: this.state.waiver_content_5} } />

                            <div className="check-bottom-exist"></div>
                        </div>
                        <div className="mt16 clearfix" style={{paddingBottom: "30px", paddingRight: "15px", width: "12.5%", position: "relative"}}>
                            <span id="icon-scroll" className="float-right icon-scroll" style={styles.scrollPointer}>
                            <FaHandPointLeft className="fa fa-hand-o-left fa-lg"/> 
                                SCROLL DOWN TO SIGN 
                            </span>
                        </div>
                    </div>
                    <div style={{textAlign: "center"}} className={`${this.state.accept_sign ? '' : 'd-none'}`}>
                        <a id="signing-button" role="button" className="btn btn-primary signing-btn" data-toggle="modal" data-target="#modalaccept" onClick={this.toggleOpenSignModal}>
                            Accept &amp; Sign
                        </a>
                    </div>
                    <div>
                        <Modal 
                            backdrop={true}
                            keyboard={false}
                            centered={true}
                            autoFocus={true}
                            zIndex={2000}
                            isOpen={this.state.openSignWaiver} 
                            toggle={this.toggleOpenSignModal}
                            >
                            <ModalHeader className="modal-warning">
                                <div>
                                    <b>Validate Waiver</b>
                                </div>
                            </ModalHeader>
                            <ModalBody className="modal-warning">
                                <div className="signature-canvas-header">
                                    <strong>
                                        Draw your signature
                                    </strong>
                                    <div onClick={this.clearSignature}>
                                        Clear
                                    </div>
                                </div>
                                <div className="signature-canvas-wrapper" style={{width:"100%", background:"#333"}}>
                                    <SignatureCanvas 
                                        ref={(ref) => { this.sigCanvasRef = ref }}
                                        backgroundColor="white"
                                        penColor="black"
                                        canvasProps={{width: 472, height: 242, maxWidth: 1}}
                                        onEnd={()=>{
                                            const trimmedCanv = this.sigCanvasRef.getTrimmedCanvas();
                                            const base64data = trimmedCanv.toDataURL();

                                            this.setState({signatureValue: base64data});
                                        }}
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter className="modal-warning">
                                <Button 
                                className="btn btn-secondary" onClick={this.signWaiver}>Accept & Sign</Button>
                            </ModalFooter>
                        </Modal>
                    </div >
                </div>
                <button type="button" className="action-button-previous" value="Previous" onClick={() => this.previousPage()}><FaArrowLeft/> Back</button>
            </Form>
            :
            <></>
        );
    }
}
export default SignWaiverCard
