import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class StepperVoucher extends Component {
    state = {
        park_data: {}
    }
    
    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.parks) {
            let park_data = newProps.parks.filter(park => park.id == newProps.park_id)
            this.setState({ park_data: park_data[0] })
        }
    }
    
    render() {
        let { park_data } = this.state;
        return (
            <div className="nav" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    {park_data && park_data.logo_web ?
                        <a><img src={`data:image/png;base64,${park_data.logo_web}`} height="30" /></a> :
                        <a> TARONGA ZOO </a>
                    }
                </div>
                <div>
                    {park_data && park_data.website ?
                        <a href={park_data.website}>Back to website</a> :
                        <a href='/'> Back to website </a>
                    }
                </div>
            </div>
        );
    }
}
export default StepperVoucher
