import React, { Component } from "react";
import AbsaleCard from "./../../OrderProcess/AbsaleCard";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input
} from "reactstrap";

// import "react-phone-input-2/dist/style.css";
let hasActive = false;
let fields = [];

class Contact extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    first_name: "",
    btnPay: false,
    validate: { emailState: "", fnState: "", nameState: "", phoneState: "" }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.ContactCollapse != this.props.ContactCollapse) {
      this.setState({ btnPay: newProps.enableSubmit });
      let fields = [];
      let value = this.state;
      Object.keys(this.state).forEach(function(key) {
        fields[key] = value[key].length;
      });
      this.fieldCheck(fields);
    }
  }

  render() {
    const { cardStyle, numberCircle, cardClass } = this.props;
    const headCard = this.getheadCard();
    const cardCollapse = this.getCardDetail();

    return (
      <AbsaleCard
        cardStyle={cardStyle}
        cardClass={cardClass}
        numberCircle={numberCircle}
        headCard={headCard}
        step="3"
        cardCollapse={cardCollapse}
        collapse={this.props.ContactCollapse}
        data={this.props.data}
        actionCollapse={this.props.actionCollapse}
        name="ContactCollapse"
        parks={this.props.parks}
      />
    );
  }

  fieldCheck(fields) {
    if (
      fields["first_name"] != null &&
      fields["name"] != null &&
      fields["email"] != null &&
      fields["phone"] != null
    ) {
      if (
        fields["first_name"] > 0 &&
        fields["name"] > 0 &&
        fields["email"] > 0 &&
        fields["phone"] > 1
      ) {
        this.setState({ btnPay: true });
      } else {
        this.setState({ btnPay: false });
      }
    }
  }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
    if (emailRex.test(e.target.value)) {
      validate.emailState = "has-success";
    } else {
      validate.emailState = "has-danger";
    }
    this.setState({ validate });
  }

  validBlur(e) {
    const { validate } = this.state;
    validate.fnState = "has-success";
    this.setState({ validate });
  }

  validBlurName(e) {
    const { validate } = this.state;
    validate.nameState = "has-success";
    this.setState({ validate });
  }

  validBlurPhone(e) {
    if (e.target.value !== ''){
      const phoneRegex = /^[().+\d -]{1,15}$/;
      const { validate } = this.state;
      if (phoneRegex.test(e.target.value) && e.target.value.length > 6) {
        validate.phoneState = "has-success";
      } else {
        validate.phoneState = "has-danger";
      }
      this.setState({ validate });
    } else {
      // eslint-disable-next-line
      this.state.validate.phoneState = "";
    }
  }
  handleChange(e) {
    fields[e.target.name] = e.target.value.length;
    this.setState({ [e.target.name]: e.target.value });
    this.fieldCheck(fields);
  }

  handleChangeNumber(e) {
    const value = e.target.value.match(/^[().+\d -]{1,15}$/g, "");
    fields[e.target.name] = e.target.value.length;
    this.setState({ [e.target.name]: value });
    this.fieldCheck(fields);
  }
  handleSubmit(event) {
    alert("An essay was submitted: " + this.state.value);
  }

  getheadCard() {
    let detail = [];
    if (!this.props.ProfileCardCollapse) {
      detail.push(
        <div
          key={"head-card-1"}
          className="text-secondary font-weight-bold row col-md-12"
        >
          <div className="col-md-5 div-flex">
            <div>{this.state.first_name + " " + this.state.name}</div>
          </div>
          {/* TODO */}
          <div className="col-md-7 small-text" style={{ paddingRight: "#0px" }}>
            {this.state.email}
            <br />
            <div className="small-text">
              {this.state.phone !== 0 && "" + this.state.phone}
            </div>
          </div>
        </div>
      );
    }
    if (this.props.ProfileCardCollapse) {
      hasActive = true;
    }
    return (
      <React.Fragment>
        <div
          className={
            hasActive
              ? "font-weight-bold title"
              : "font-weight-bold title head-dis"
          }
        >
          Contact details
        </div>
        {detail}
      </React.Fragment>
    );
  }
  getCardDetail() {
    const cardCollapse = (
      <Card>
        <CardBody className="cardbody-step3">
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (this.state.validate.phoneState !== "has-danger") {
                this.props.action(this.state);
                this.setState({ btnPay: !this.state.btnPay });
              }
            }}
          >
            <Label for="numberParticipant" className="g-color">
              Please enter your personal info
            </Label>
            <FormGroup className="field-step3">
              <Input
                valid={this.state.validate.fnState === "has-success"}
                autoComplete="off"
                type="text"
                name="first_name"
                id="first_name"
                className="form-control step3"
                placeholder="First Name*"
                required
                value={this.state.first_name}
                onBlur={e => {
                  this.validBlur(e);
                }}
                onChange={e => {
                  // this.validateText(e)
                  this.handleChange(e);
                }}
              />
              <FormFeedback className="ftnamevalid" valid>
                First Name *
              </FormFeedback>
            </FormGroup>
            <FormGroup className="field-step3">
              <Input
                valid={this.state.validate.nameState === "has-success"}
                autoComplete="off"
                type="text"
                name="name"
                id="name"
                className="form-control step3"
                placeholder="Last Name*"
                required
                value={this.state.name}
                onBlur={e => {
                  this.validBlurName(e);
                }}
                onChange={this.handleChange.bind(this)}
              />
              <FormFeedback className="namevalid" valid>
                Last Name *
              </FormFeedback>
            </FormGroup>
            <FormGroup className="field-step3">
              <Input
                autoComplete="off"
                type="email"
                name="email"
                id="email"
                className="form-control step3"
                placeholder="Email*"
                required
                value={this.state.email}
                onChange={e => {
                  // force email to be lower case
                  e.target.value = e.target.value.toLowerCase();
                  this.validateEmail(e);
                  this.handleChange(e);
                }}
                valid={this.state.validate.emailState === "has-success"}
                invalid={this.state.validate.emailState === "has-danger"}
              />
              <FormFeedback className="emailvalid" valid>
                Email *
              </FormFeedback>
              <FormFeedback>Provide a valid email</FormFeedback>
            </FormGroup>
            <FormGroup className="field-step3">
              <Input
                type="text"
                name="phone"
                id="phone"
                className="form-control step3"
                placeholder="Mobile*"
                required
                value={this.state.phone}
                valid={this.state.validate.phoneState === "has-success"}
                invalid={this.state.validate.phoneState === "has-danger"}
                onBlur={e => {
                  this.validBlurPhone(e);
                }}
                onChange={e => {
                  this.validBlurPhone(e);
                  this.handleChange(e);
                }}
              />
              <FormFeedback className="phonevalid" valid>
                Phone *
              </FormFeedback>
              <FormFeedback>Provide a valid mobile number</FormFeedback>
            </FormGroup>
            <Button className="btn" type="submit" disabled={!this.state.btnPay}>
              GO TO CHECKOUT
            </Button>
          </Form>
        </CardBody>
      </Card>
    );
    return cardCollapse;
  }
}

export default Contact;
