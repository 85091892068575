import React, { Component } from "react";
import AbsaleCard from "../AbsaleCard";
import Moment from "moment";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import "react-credit-cards/lib/styles-compiled.css";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from "./utils";

import WindcaveCard from "../step6/WindcaveCard";

import { getErrors, convertToTime } from "../../../helpers/helpers"

import {
  FaUserAlt,
  FaInfoCircle,
  FaTrash,
  FaCalendarDay,
  FaCheck
} from "react-icons/fa";

let hasActive = false,
  numberError = "",
  expireError = "",
  cvcError = ""

function shallowEqual(object1, object2) {
  if (object1.length !== object2.length) {
    return false;
  }
  for (let index = 0; index < object1.length; index++) {
      if (object1[index].msg !== object2[index].msg) {
          return false;
      }
  }
  return true;
}

class PaymentMethodCard extends Component {
  state = {
    number: "",
    name: "",
    expiry: "",
    cvv: "",
    voucher: "",
    issuer: "",
    focused: "",
    formData: null,
    nameClass: "",
    numberClass: "",
    expireClass: "",
    cvcClass: "",
    checkTerm: false,
    checkSupervision: false,
    productSelected: null,
    secondActivity: null,
    terms_and_conditions: "",
    age_and_supervision_requirements: "",
    checkboxList: [],
    isVoucher: false,
    showVoucherPromo: false,
  };


  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.PaymentMethodCardCollapse !== this.props.PaymentMethodCardCollapse) {
      this.setState({ checkTerm: newProps.enableSubmit })
      
      //force scroll to payment method after card open
      setTimeout(() =>{ 
        window.scrollTo({
          top: 500,
          behavior: 'auto',
        })
      }, 1500);
    }

    let parks = this.props.parks;
    if (parks !== null && parks.length > 0) {
        let park_data = this.props.parks.filter(park => park.id == this.props.park_id)[0];
        if (park_data !== undefined) {
            this.setState({
                terms_and_conditions: park_data.terms_and_conditions_link,
                age_and_supervision_requirements: park_data.age_and_supervision_requirements_link,
            });
        }
    }

      this.setState({
          productSelected: this.props.productSelected,
          secondActivity: this.props.secondActivity,
      }) 

      let mainActivityTickbox = this.state.productSelected ? this.state.productSelected.experience_id.tickbox_rule_ids : [];
      let secondActivityTickbox = this.state.secondActivity ? this.state.secondActivity.experience_id.tickbox_rule_ids : [];
      let tickboxesFiltered = mainActivityTickbox.concat(secondActivityTickbox)
          .map(item => item.tickbox_message )
          .filter((x, i, a) => a.indexOf(x) == i);
      let newcheckboxList = tickboxesFiltered.map(tb => {
                  return {"msg": tb, isChecked: false}
              })
      if (!shallowEqual(this.state.checkboxList, newcheckboxList)) {
          this.setState({
              checkboxList: newcheckboxList,
          }) 
      }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.PaymentMethodCardCollapse && prevProps.voucher.id) {
      this.props.voucher.id = null 
      this.props.voucher.amount = 0
      this.props.voucher.valid = null 
      this.props.voucher.message = "" 
      this.props.voucher.code = "" 
      this.props.voucher.Type = ""
    }
  }
  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      if (target.value.length > 0) {
        this.setState({ numberClass: "not-empty" });
      } else {
        this.setState({ numberClass: "" });
      }
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      if (target.value.length > 0) {
        this.setState({ expireClass: "not-empty" });
      } else {
        this.setState({ expireClass: "" });
      }
    } else if (target.name === "cvv") {
      target.value = formatCVC(target.value);
      if (target.value.length > 0) {
        this.setState({ cvcClass: "not-empty" });
      } else {
        this.setState({ cvcClass: "" });
      }
    } else if (target.name === "name") {
      if (target.value.length > 0) {
        this.setState({ nameClass: "not-empty" });
      } else {
        this.setState({ nameClass: "" });
      }
    }

    this.setState({ [target.name]: target.value });
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  render() {
    const { cardStyle, numberCircle, cardClass } = this.props;
    const { headCard, cardCollapse } = this.getCardDetail();
    
    return (
      <AbsaleCard
        cardStyle={cardStyle}
        cardClass={cardClass}
        numberCircle={numberCircle}
        headCard={headCard}
        step="5"
        cardCollapse={cardCollapse}
        collapse={this.props.PaymentMethodCardCollapse}
        data={this.props.data}
        actionCollapse={this.props.actionCollapse}
        name="PaymentMethodCardCollapse"
        parks={this.props.parks_all}
      />
    );
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckboxTerm = e => {
    this.setState({ checkTerm: !this.state.checkTerm });
  };


  handleCheckboxSupervision = e => {
    this.setState({ checkSupervision: !this.state.checkSupervision });
  };

  onAddingItem = (i) => (event) => {
    this.setState((state, props) => {
      state.checkboxList[i].isChecked = !state.checkboxList[i].isChecked;
      return {
        checkboxList: state.checkboxList
      }
    })
  }

  getOpenTickBox() {
      let tickboxes = this.state.checkboxList.map((t,i) => {
          return (
              <div key={`${i}-tick-boxes`}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={t.isChecked}
                  onChange={this.onAddingItem(i)}
                />
                <span className="font-color-step5 letterspace">
                  <small className="term-link">
                    {t.msg}
                  </small>
                </span>
              </Label>
              <br />
              </div>
          )
      });

      return (
        <div>
          {tickboxes}
        </div>
     )
  }
  
  
  getError(){
    var ewayError = this.props.payErrorMsg.split("/");
    var msg = "";

    if (ewayError.length == 1) {
        msg = "Internal Error: There was a problem processing your booking, our engineers have been informed and you have not been charged.";
    } else {
        var errorCode = ewayError[0].trim();
        var userErrorMsg = getErrors(errorCode);
        if (userErrorMsg !== undefined) {
            msg = "Incorrect " + userErrorMsg + " entered. Please try again. Eway error code: " + errorCode + ".";
        } else {
            msg = "Error: ("+ errorCode +") " + ewayError[1] + ".";
        }
    }

    const errorMsg = (
      <div>
        <span>Payment Unsuccessful.</span> <br />
        {msg}
      </div>
    );
    return errorMsg;
  }

  getVoucher() {
    let voucher = [];
    // eslint-disable-next-line
    this.props.voucher_list.map(v => {
      voucher.push(
        <li key={v.code}>
          {v.code} &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;${v.amount.toFixed(2)}
          <button
            className="btn bt-danger"
            onClick={() => this.props.removeVoucher(v.id, v.code)}
            disabled={this.props.voucher_button}
          >
            <FaTrash />
          </button>
        </li>
      );
    });
    return voucher;
  }

  getCardDetail() {
    if (this.props.PaymentMethodCardCollapse) {
      hasActive = true;
    }
    const headCard = (
      <React.Fragment>
        <div
          className={
            hasActive
              ? "font-weight-bold title"
              : "font-weight-bold title head-dis"
          }
        >
          Payment Method
        </div>
        <div className="text-secondary hidden">_</div>
      </React.Fragment>
    );

    let valDate = "";
    if (this.props.datetime_booking.date && this.props.datetime_booking.time) {
      // Check type of time
      let time = this.props.datetime_booking.time;
      if (typeof time != 'string') {
        time = convertToTime(time);
      }
      // Prepare date with moment to format
      let mDate = Moment(this.props.datetime_booking.date);
      if (mDate.isValid()) {
        valDate = mDate.format("dddd D MMMM") + " at " + time;
      }else {
        valDate = this.props.datetime_booking.date + " at " + time;
      }
    }

    let creditInfo = "";

    if (this.props.remaining_payment > 0) {
      creditInfo = (
        <React.Fragment>
          <FormGroup>
            <Label className="font-color-step5 font-weight-bold">Payment</Label>
            <br />
            <span>
              <img src="/master_s.png" alt="master" /> &nbsp;
              <img src="/visa_s.png" alt="visa" /> 
            </span>
          </FormGroup>{" "}
          <FormGroup>
            <div className="input-group-prepend">
              <div className="input-group">
                <Input
                  type="text"
                  name="name"
                  className={this.state.nameClass + " form-control padl"}
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  placeholder="Name on the card"
                  autoComplete="off"
                  disabled={this.props.remaining_payment <= 0}
                />
              </div>
              <i className={this.state.nameClass + " icon-on-input"}>
                <FaUserAlt />
              </i>
            </div>
          </FormGroup>
          <FormGroup>
            <div className={"input-group-prepend " + numberError}>
              <div className="input-group">
                <Input
                  type="tel"
                  name="number"
                  className={this.state.numberClass + " form-control padl"}
                  placeholder="Card Number"
                  pattern="[\d| ]{16,22}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  autoComplete="off"
                  disabled={this.props.remaining_payment <= 0}
                />
              </div>
              <i className={this.state.numberClass + " icon-on-input"}>
                <FaInfoCircle />
              </i>
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className={"col-md-5 " + expireError}>
              <Input
                type="tel"
                name="expiry"
                id="expiry"
                className={this.state.expireClass + " form-control"}
                placeholder="MM/YY"
                pattern="\d\d/\d\d"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                autoComplete="off"
                disabled={this.props.remaining_payment <= 0}
              />
            </div>
            <div className={"col-md-5 div-flex " + cvcError}>
              <Input
                type="text"
                name="cvv"
                className={this.state.cvcClass + " form-control"}
                placeholder="CVV"
                // pattern="\d{3/4}"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                autoComplete="off"
                maxLength="3"
                disabled={this.props.remaining_payment <= 0}
              />
            </div>
          </FormGroup>
        </React.Fragment>
      );
    }
    const cardCollapse = (
      <>
      <Card key="Payment">
        <CardBody>
          <div style={{display: "flex", justifyContent: "space-between", marginBottom: "18px"}}>
            <Button 
              className="btn font-weight-bold" 
              style={{borderRadius: "6px", width: "49%"}}
              onClick={()=>{
                if(!this.state.showVoucherPromo || (!this.state.isVoucher && this.state.showVoucherPromo)){ 
                  this.setState({ showVoucherPromo: true  });
                }else{
                  this.setState({ showVoucherPromo: false  });
                }
                this.setState({ isVoucher: true  });
              }}>
              Add Voucher
            </Button>
            <Button 
              className="btn font-weight-bold"
              style={{borderRadius: "6px", width: "49%"}}
              onClick={()=>{
                if(!this.state.showVoucherPromo || (this.state.isVoucher && this.state.showVoucherPromo)){ 
                  this.setState({ showVoucherPromo: true  });
                }else{
                  this.setState({ showVoucherPromo: false  });
                }
                this.setState({ isVoucher: false  });
              }}>
              Add Promo Code
            </Button>
          </div>
          {this.state.showVoucherPromo ? 
           (<Form
              className={!this.state.isVoucher ? "voucher-form promo" : "voucher-form"}
              style={{ display: "flex", width: "100%", alignItems: "center" }}
              onSubmit={e => {
                e.preventDefault();
                this.setState({ voucher: "" });
                this.props.checkVoucher(this.state.voucher, this.state.isVoucher).then(() => {
                  if (this.props.voucher.valid === true) {
                    this.setState({ voucher: "" });
                  }
                });
              }}
            >
              <FormGroup className="mb-0 mr-sm-0 mb-sm-0" style={{width: "100%"}}>
                <Input
                  valid={this.props.voucher.valid === true}
                  invalid={this.props.voucher.valid === false}
                  type="text"
                  name="voucher"
                  id="voucher"
                  required
                  className="form-control padlw"
                  value={this.state.voucher}
                  autoComplete="off"
                  placeholder={this.state.isVoucher ? "Add Voucher" : "Apply Promo Code"}
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  // disabled={this.props.voucher.valid}
                />
              </FormGroup>
              <Button
                type="submit"
                style={{lineHeight: "15px"}}
                disabled={this.props.voucher_button}
              >
                Add
              </Button>
            </Form>)
              : ""
          }

          {
            /* if success, no need to show message */
            this.props.voucher.valid === true ? 
            <Label for="exampleSelect" className={"success-customer-promo" }></Label> : 
            <Label for="exampleSelect" className={"warning-customer-promo"}>{this.props.voucher.message}</Label>
          }

          {/* TODO */}
          <ul className="lsstyle mb-0">{this.getVoucher()}</ul>
          {
            // window.innerWidth < 1000 ?
            //   "" : 
            //   <Form
            //     onSubmit={e => {
            //       e.preventDefault();
            //       this.props.action({
            //         name: this.state.name,
            //         cvv: this.state.cvv,
            //         number: this.state.number,
            //         expiry: this.state.expiry
            //       });
            //       this.setState({ checkTerm: false });
            //     }}
            //   ><div
            //     className={
            //       this.props.payError
            //         ? "card-step5 pay-error "
            //         : "card-step5 "
            //     }
            //   >
            //     {/* TODO */}
            //     <div className="mb-2">
            //       <span>
            //         {this.props.productSelected
            //           ? this.props.productSelected.company_id[1]
            //           : ""}{" "}
            //       </span>
            //       <br />
            //       <i>
            //         <FaCalendarDay />
            //       </i>{" "}
            //       <span>{valDate}</span> <br />
            //       <i>
            //         <FaUserAlt />
            //       </i>{" "}
            //       <span>{this.props.summary_participant}</span>{" "}
            //       <br />
            //       {this.props.productSelected && (
            //         <React.Fragment>
            //           {" "}
            //           <i>
            //             <FaCheck />
            //           </i>
            //           {/* <span>{this.props.productSelected.experience_id.name}</span> */}
            //           <span>{this.props.productSelected.name}</span>
            //         </React.Fragment>
            //       )}
            //       <br />
            //       {this.props.secondActivity && (
            //         <React.Fragment>
            //           {" "}
            //           <i>
            //             <FaCheck />
            //           </i>
            //           {/* <span>{this.props.secondActivity.experience_id.name}</span> */}
            //           <span>{this.props.secondActivity.name}</span>
            //         </React.Fragment>
            //       )}
            //     </div>
            //   </div>
            // </Form> 
          }
          <div className={this.props.payError ? "pay-error-msg" : "no-error"}>
            {this.props.payError ? this.getError() : ""}
          </div>
        </CardBody>
      </Card>
      </>
    );
    return { headCard, cardCollapse };
  }
}

export default PaymentMethodCard;
