import React, { Component, useState } from "react";
import AbsaleCard from "./../AbsaleCard";
import { FaApple } from "react-icons/fa";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";

let hasActive = false;
let shake= "";
var shouldToggle = false;

function shallowEqual(object1, object2) {
  if (object1.length !== object2.length) {
    return false;
  }
  for (let index = 0; index < object1.length; index++) {
      if (object1[index].msg !== object2[index].msg) {
          return false;
      }
  }
  return true;
}
class WindcaveCard extends Component {
  state = {
    hasCheckApproval: false,
    currentWindcaveLink: undefined,
    checkTerm: false,
    checkSupervision: false,
    terms_and_conditions: "",
    age_and_supervision_requirements:"",
    checkboxList: [],
    productSelected: null,
  };
  
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.PaymentCollapse !== this.props.PaymentCollapse) {
      this.setState({ checkTerm: newProps.enableSubmit })
    }
    let parks = this.props.parks;
    if (parks !== null && parks.length > 0) {
        let park_data = parks.filter(park => park.id == this.props.park_id)[0];
        if (park_data !== undefined) {
            this.setState({
                terms_and_conditions: park_data.terms_and_conditions_link,
                age_and_supervision_requirements: park_data.age_and_supervision_requirements_link,
            });
        }
    }
      this.setState({
          productSelected: this.props.productSelected,
          secondActivity: this.props.secondActivity,
      }) 

      let mainActivityTickbox = this.state.productSelected ? this.state.productSelected.experience_id.tickbox_rule_ids : [];
      let secondActivityTickbox = this.state.secondActivity ? this.state.secondActivity.experience_id.tickbox_rule_ids : [];
      let tickboxesFiltered = mainActivityTickbox.concat(secondActivityTickbox)
          .map(item => item.tickbox_message )
          .filter((x, i, a) => a.indexOf(x) == i);
      let newcheckboxList = tickboxesFiltered.map(tb => {
                  return {"msg": tb, isChecked: false}
              })
      
      if (!shallowEqual(this.state.checkboxList, newcheckboxList)) {
          this.setState({
              checkboxList: newcheckboxList,
          }) 
      }
  }
  onAddingItem = (i) => (event) => {
    this.setState((state, props) => {
      state.checkboxList[i].isChecked = !state.checkboxList[i].isChecked;
      return {
        checkboxList: state.checkboxList
      }
    })
  }

  getOpenTickBox() {
      let tickboxes = this.state.checkboxList.map((t,i) => {
          return (
              <div key={`${i}-tick-boxes`}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={t.isChecked}
                  onChange={this.onAddingItem(i)}
                />
                <span className="font-color-step5 letterspace">
                  <small className="term-link">
                    {t.msg}
                  </small>
                </span>
              </Label>
              <br />
              </div>
          )
      });

      return (
        <div>
          {tickboxes}
        </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    var self = this;
    
    //to_be_checked
    if (
      (!self.props.error.errorCode && prevProps.windcaveLink !== self.props.windcaveLink) || 
      (self.props.errorCode && prevProps.windcaveLink != undefined)
    ){
      window.addEventListener('message', function(e) {
        if (e.data.trigger === "approved" && !e.data.success) {
          self.props.action();
          self.setState({
            hasCheckApproval: true,
          })
          e.data.success = true;
        } else if (e.data.trigger === "cancelled" && !e.data.success) {
          self.props.actionWindcave(true);
          self.setState({
            hasCheckApproval: true,
          });
          shouldToggle = false;
          e.data.success = true;
        } else if (e.data.trigger === "declined" && !e.data.success) {
          self.props.actionWindcave(true);
          self.setState({
            hasCheckApproval: true,
          })
          e.data.success = true;
        }
        
        if (e.data.success && prevState.hasCheckApproval) {
          e.data.success = false;
        }
  
      }, false);
  
      if (self.state.hasCheckApproval && prevState.hasCheckApproval) {
          self.setState({
            hasCheckApproval: false,
          })
      }
    }

  }

  hideSpinner = () => {
    if (this.props !== undefined && !shouldToggle) {
      // check if current link is undefined of the same ss
      this.props.toggleLoading(false);
      shouldToggle = false
    } else {
      shouldToggle = true
    }
  }

  handleCheckbox = (e) => {
    this.setState({ checkTerm: !this.state.checkTerm });
  }

  handleCheckboxSupervision = e => {
    this.setState({ checkSupervision: !this.state.checkSupervision });
  };

  getError = () => {
    const error = "(" + this.props.error.errorCode + ") " + this.props.error.errorDetail;
    return error
  }

  render() {
    const { cardStyle, numberCircle, cardClass } = this.props;
    const total_amount = this.props.totalAmount;
    const headCard = this.getheadCard();

    const cardCollapse = (
      <>
        <Card key="payment">
          <CardBody className="cardbody-step3">
            <Form
              onSubmit={e => {
                e.preventDefault();
                shake= "";
                this.setState({ checkTerm: false });
              }}
            >
              <div className="card-payment">
              <FormGroup check style={this.props.type == 'booking_payment' ? {marginLeft: "-25px"} : {}}>
                <Label check>

                  <Input type="checkbox" checked={this.state.checkTerm} onChange={e => this.handleCheckbox(e.target.value)} />
                  <span className="font-color-step5 letterspace">
                    <small className="term-link">
                      I accept{" "}
                      <a href={this.state.terms_and_conditions} target="_blank" rel="noopener noreferrer">
                        the terms and conditions
                      </a>.
                    </small>
                  </span>
                </Label>
                <br/>
                { this.props.type == 'order' ?
                <>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.checkSupervision}
                    onChange={e => this.handleCheckboxSupervision(e.target.value)}
                  />
                  <span className="font-color-step5 letterspace">
                    <small className="term-link">
                      I have read and understood that there are{" "}
                      <a href={this.state.age_and_supervision_requirements} target="_blank" rel="noopener noreferrer">
                          age and supervision requirements
                      </a> that apply.
                    </small>
                  </span>
                </Label>
                </> : ""
              }
                {/* <div className={this.props.payError ? "card-step5 pay-error " + shake : "card-step5 "}> */}
                {/* TODO */}
                { this.props.type == 'order' ? this.getOpenTickBox() : ""}
                
                <div className={this.props.error ? "pay-error-msg" : "no-error"}>
                  <b>{this.props.error.errorCode ? this.getError() : ""}</b>
                </div>
              </FormGroup>    
              </div>        
            </Form> 
          </CardBody>
        </Card> 
        {/* : <></> } */}
        <Card>
          <CardBody className="cardbody-step3" style={{}}>
                {
                  total_amount > 0 ?
                  <div style={ 
                    (
                      (!this.state.checkTerm && (this.props.type == "voucher" || this.props.type == "booking_payment")) || 
                      (!(this.state.checkTerm && this.state.checkSupervision && !this.state.checkboxList.map(cb => {
                          return cb.isChecked
                      }).includes(false)) && this.props.type == "order") 
                    ) 
                    
                    ? {overflow: "hidden", pointerEvents: "none", opacity: 0.5, marginLeft: "-41px", marginTop: "-45px"} : {marginLeft: "-41px", marginTop: "-45px"} }>

                  <br/>
                  {this.props.isIOS ? 
                  <div className="mb-4" style={{ textAlign: 'center', color: "#262B4D" }}>
                    <h3 className="mb-3 apple-pay-btn" >Pay with</h3>
                    <Button
                      type="submit"
                      size="lg"
                      onClick={this.props.getNewSession}
                      style={{ fontSize: "1.5rem", display: 'inline-block', justifyContent: 'center', alignItems: 'center', borderRadius: '0px', backgroundColor: 'black', width: '90%'}}
                      block
                    >
                      <FaApple style={{ marginBottom: '7px' }}/>Pay
                    </Button>
                  </div>: ''} 

                  <iframe
                    id="responsive-windcave-iframe"
                    className="responsive-iframe"
                    src={this.props.windcaveLink}
                    scrolling="auto"
                    frameBorder="0"
                    onLoad={this.hideSpinner}>
                  </iframe>
                  </div>
                  :
                  <div style={ 
                    (
                      (!this.state.checkTerm && (this.props.type == "voucher" || this.props.type == "booking_payment")) || 
                      (!(this.state.checkTerm && this.state.checkSupervision && !this.state.checkboxList.map(cb => {
                          return cb.isChecked
                      }).includes(false)) && this.props.type == "order") 
                    ) 
                    
                    ? {overflow: "hidden", pointerEvents: "none", opacity: 0.5, marginLeft: "0px"} : {marginLeft: "0px"} }>
                  <Button
                    type="submit"
                    size="lg"
                    onClick={this.props.action}
                    style={{ fontSize: "1.25rem" }}
                    block
                  >
                    Confirm Booking
                  </Button>
                  </div>
                }
          </CardBody>
        </Card>
      </>
    );
    return (
      // this.props.type == "voucher" ?
      <AbsaleCard
        cardStyle={cardStyle}
        cardClass={cardClass}
        numberCircle={numberCircle}
        headCard={headCard}
        step={this.props.step}
        cardCollapse={cardCollapse}
        collapse={this.props.WindcaveCardCollapse}
        data={this.props.data}
        actionCollapse={this.props.actionCollapse}
        name="WindcaveCardCollapse"
        type={this.props.type}
        parks={this.props.parks_all}
      />
      // : ""
    );
  }

  getheadCard() {
    let detail = [];
    if (!this.props.WindcaveCardCollapse) {
      detail.push(
        <div key={'head-card-1'} className="text-secondary font-weight-bold row col-md-12">
        </div>
      );
    }
    if (this.props.WindcaveCardCollapse) {
      hasActive = true;
    }
    return (
      <React.Fragment>
        {
          this.props.step ?
          <div className={hasActive ? "font-weight-bold title" : "font-weight-bold title head-dis"}>Payment Method</div>
          : ""
        }
        {detail}
      </React.Fragment>
    );
  }
}

export default WindcaveCard;

